import styled from "styled-components";
import searchIcon from "assets/magnifying_icon.svg";
import downArrow from "assets/down_arrow.svg";
import downArrowGrey from "assets/down_arrow_grey.svg";
import calendar from "assets/calendar.svg";

const Wrapper = styled.div`
  padding-top: 6.25rem;

  @media (min-width: 1366px) {
    border-bottom: 2px solid #f0f0f0;
    padding: 1.5rem 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .menu {
    display: flex;
    flex-direction: column;

    @media (min-width: 1366px) {
      flex-direction: row;
    }
  }

  .title {
    display: none;

    @media (min-width: 1366px) {
      color: ${(props) => props.theme.colors.secondary};
      display: initial;
      font-size: 1.75rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .user-details {
    display: none;

    img {
      height: 45px;
      width: auto;
      border-radius: 50%;
    }

    @media (min-width: 1366px) {
      display: flex;
      gap: 1rem;
      align-items: center;
      position: relative;

      .pipe {
        border: 0.5px solid grey;
        height: 2.5rem;
      }

      .view-sync {
        cursor: pointer;
        width: 2rem;
        height: auto;
      }

      .sync-late,
      .sync-on-time {
        height: 0.7rem;
        width: 0.7rem;
        background: #ff1c1c;
        border-radius: 50%;
        position: absolute;
        bottom: 0.5rem;
        right: 0;
      }

      .sync-on-time {
        background: #2fd800;
      }
    }
  }

  .show-search-toggle {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.secondary};
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.5rem 0;
    text-align: center;

    @media (min-width: 1366px) {
      display: none;
    }
  }

  .hidden {
    display: none;

    @media (min-width: 1366px) {
      display: initial;
    }
  }

  .dropdown-container {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
      width: fit-content;
    }
  }

  .dropdown-field,
  .search-field {
    display: flex;
    font-size: 1rem;
    outline: none;
    padding-left: 1.125rem;
    width: 100%;
  }

  .dropdown-field {
    background-color: #1d5c87;
    background-image: url(${downArrow});
    background-position-x: 92.5%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    border: none;
    color: #fff;
    font-weight: 700;
    height: 2.625rem;
    padding-right: 0.375rem;
    -webkit-appearance: none;
    -moz-appearance: none;

    @media only screen and (min-width: 1024px) {
      border-radius: 0.625rem 0 0 0.625rem;
    }
  }

  .search-field {
    background-color: #f8f8f8;
    background-image: url(${searchIcon});
    background-position-x: 92.5%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    border: 1px solid #1a6698;
    color: #1a6698;
    font-weight: 500;
    padding-right: 3.25rem;
    height: 2.625rem;

    &::placeholder {
      color: #1a6698;
    }

    @media only screen and (min-width: 1024px) {
      border-radius: 0 0.625rem 0.625rem 0;
    }
  }

  .select-field {
    background-image: url(${downArrowGrey});
    background-size: initial;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .date-field {
    background-image: url(${calendar});
    padding-right: 0.125rem;
    width: 100%;

    &::placeholder {
      content: attr(data-placeholder);
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      opacity: 0;
    }

    &:focus::before,
    &:valid::before {
      display: none;
    }
  }

  .start-date {
    border-radius: 0;
    border-right: none;
  }
`;

export { Wrapper };
