import { makeAutoObservable } from "mobx";
import { getAdLogs, getAds } from "services/AdsApi";

class AdsStore {
  ads = [];
  adLogs = [];
  adCount = null;
  loading = true;
  adQueryParams = {};
  queryExport = {}
  locations = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAds = (ads) => (this.ads = ads);
  setAdCount = (adCount) => (this.adCount = adCount);
  setAdLogs = (adLogs) => (this.adLogs = adLogs);
  setAdLogsCount = (adLogsCount) => (this.adLogsCount = adLogsCount);
  setLoading = (loading) => (this.loading = loading);
  setAdQueryParams = (params) => (this.adQueryParams = params);
  setAdsExport = (queryExport) => (this.queryExport = queryExport);
  setUserLocations = (locations) => (this.locations = locations);

  storeGetAds = async (filters, role, location) => {
    this.setLoading(true);
    this.setAdQueryParams(filters);
    try {
      const data = await getAds(filters, role, location).then((res) => res);
      const { ads, totalRows } = data;
      this.setAds(ads);
      this.setAdCount(totalRows);
      this.setLoading(false);
    } catch (err) {
      /* do nothing */
    }
  };

  storeGetAdLogs = async (filters, role, location) => {
    this.setLoading(true);
    try {
      const data = await getAdLogs(filters, role, location).then((res) => res);
      const { ads } = data;
      this.setAdLogs(ads);
      this.setLoading(false);
    } catch (err) {
      /* do nothing */
    }
  };
}

export default new AdsStore();
