import React from 'react';

function TextInput({searchParams, handleChange, name}) {

    return (
        <input 
            className='search-field'
            onChange={handleChange}
            name={name}
            placeholder='Search'
            value={searchParams.filterValue}
            type='text'
        />
    )
}

export default TextInput;
