import styled from "styled-components";

const Form = styled.form`
  text-align: center;
  max-width: 30rem;
  margin: 0 auto;

  .title {
    color: #fff;
    font-size: 1.75rem;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: #fff;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  .otp-input {
    justify-content: space-around;
    margin-bottom: 3rem;
    width: 100%;

    input {
      background: none;
      background-position: bottom;
      border: 0;
      border-bottom: 2px solid #44444473;
      color: #fff;
      font-family: "Roboto Mono", monospace;
      font-size: 1.75rem;
      font-weight: 700;
      outline: none;

      &:focus {
        border-color: #444444;
      }
    }
  }
`;

export { Form };
