import React, { memo } from "react";

/* Component */
import AppLayout from "components/layout/AppLayout";
import AdForm from "components/features/AdForm";

function CreateAd() {
  return (
    <AppLayout page="Create Ad">
      <AdForm editMode={false} />
    </AppLayout>
  );
}

export default memo(CreateAd);
