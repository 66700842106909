import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MdOutlineDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { deleteUser } from "helpers/AdminCard.helper";
import { StoreContext } from "stores/store.context";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  border-radius: 1rem;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    width: 24.5rem;
  }

  border: 1px solid
    ${(props) =>
      props.adminType === "national"
        ? props.theme.colors.secondary
        : props.theme.colors.primary};

  background: ${(props) =>
    props.adminType === "national"
      ? props.theme.colors.secondary
      : props.theme.colors.primary};

  .content-container {
    background: ${(props) => props.theme.colors.white};
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    hr {
      height: 2px;
      background-color: lightgrey;
      border: none;
    }

    .name {
      font-weight: 600;
    }

    .role {
      font-size: 14px;
      text-transform: capitalize;
    }
  }

  .action-btn-container {
    margin-left: auto;
    display: flex;
    gap: 0.25rem;

    .delete-btn,
    .edit-btn {
      cursor: pointer;
    }
  }
`;

function AdminCard({ adminType, data }) {
  const { UiStore } = useContext(StoreContext);
  const { setLocalAdmins, setNationalAdmins } = UiStore;
  const { Attributes, Username } = data;

  const name = Attributes.find((att) => att.Name === "custom:Name")?.Value;
  const email = Attributes.find((att) => att.Name === "email")?.Value;
  const role = Attributes.find((att) => att.Name === "custom:role")?.Value;

  const handleRefetchAdmins = useCallback(() => {
    if (adminType === "local") {
      setLocalAdmins();
    }

    if (adminType === "national") {
      setNationalAdmins();
    }
  }, [adminType, setLocalAdmins, setNationalAdmins]);

  const handleDeleteUser = useCallback(
    async (Username) => {
      Swal.fire({
        title: "Are you sure you want to delete this user?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#ED7C52",
        cancelButtonColor: "#1D5C87",
        reverseButtons: true,
      }).then((data) => {
        if (data.isConfirmed) {
          deleteUser(Username)
            .then(() => {
              Swal.fire(
                "User deleted!",
                "User is successfully deleted",
                "success"
              ).then((data) => {
                if (data.isConfirmed) {
                  handleRefetchAdmins();
                }
              });
            })
            .catch((err) =>
              Swal.fire("Error!", err.message, "error").then((data) => {
                if (data.isConfirmed) {
                  handleRefetchAdmins();
                }
              })
            );
        }
      });
    },
    [handleRefetchAdmins]
  );

  return (
    <Wrapper adminType={adminType}>
      <div className="content-container">
        <p className="name">{name || email}</p>
        <hr />
        <p className="role">
          {role ? role.replaceAll("_", " ") : "No Role Assigned"}
        </p>
        <div className="action-btn-container">
          <Link className="edit-btn" to={`/update-user/${Username}`}>
            <MdOutlineEdit size={20} color="black" />
          </Link>
          <MdOutlineDeleteOutline
            className="delete-btn"
            size={20}
            onClick={() => handleDeleteUser(Username)}
          />
        </div>
      </div>
    </Wrapper>
  );
}

AdminCard.propTypes = {
  adminType: PropTypes.oneOf(["local", "national"]).isRequired,
  data: PropTypes.object.isRequired,
};

export default AdminCard;
