const {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} = require("amazon-cognito-identity-js");
const AWS = require("aws-sdk");

function formError(name, message) {
  const error = new Error(message);
  error.name = name;

  return error;
}

const CognitoLogin = {
  /** @type {CognitoUserPool} */
  userPool: null,

  sendChallenge: () => {},

  configure(awsconfig) {
    this.userPool = new CognitoUserPool({
      ClientId: awsconfig.aws_user_pools_web_client_id,
      UserPoolId: awsconfig.aws_user_pools_id,
    });
    AWS.config.region = awsconfig.aws_user_pools_id.split("_")[0];
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsconfig.aws_identity_pools_id,
    });
  },

  /**
   *
   * @param {string} username
   * @param {string} password
   * @param {{ otpStatus: (status: "sent" | "incorrect" | "resent", error: Error) => void, auth: (result: CognitoUserSession, error: Error) => void  }} callbacks
   * @returns { Promise<void> }
   */
  checkCredentials(username, password) {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: username,
        Pool: this.userPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.setAuthenticationFlowType("CUSTOM_AUTH");

      user.authenticateUser(authDetails, {
        onFailure: (err) => {
          reject(formError("CredentialsError", err.message));
        },
        customChallenge: () => {
          this.sendChallenge = (otp, resendOTP) =>
            new Promise((resolve, reject) => {
              if (resendOTP) {
                user.sendCustomChallengeAnswer(
                  ".",
                  {
                    onSuccess: () => {},
                    onFailure: (err) =>
                      reject(formError("OTPResendError", err.message)),
                    customChallenge: () => resolve(),
                  },
                  { resendOTP: "true" }
                );
              } else {
                user.sendCustomChallengeAnswer(otp, {
                  onSuccess: (result) => resolve(result),
                  onFailure: (err) =>
                    reject(formError("OTPError", err.message)),
                  customChallenge: () =>
                    reject(
                      formError("IncorrectOTPError", "Invalid or incorrect OTP")
                    ),
                });
              }
            });
          resolve();
        },
      });
    });
  },

  /** @return { Promise<import("aws-sdk/clients/databrew").ClientSessionId> } */
  async verifyOTP(otp) {
    return this.sendChallenge(otp);
  },

  async resendOTP() {
    this.sendChallenge(null, true);
  },
};

export default CognitoLogin;
