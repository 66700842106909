import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  max-width: 25rem;
  width: 100%;

  .padding {
    padding-left: 3.5rem;
  }
`;

const Icon = styled.img`
  height: 1.5rem;
  left: 1rem;
  padding-right: 1.75rem;
  position: absolute;
  top: 1rem;
  width: auto;
  z-index: 10;

  @media (min-width: 768px) {
    padding-right: 2.75rem;
  }
`;

const Input = styled.input`
  border: none;
  border-radius: 1rem;
  height: 3.5rem;
  color: #1a6698;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 1.25rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.text};
  }
`;

function TextField({ type, min, maxLength, name, onChange, value, placeholder, icon, disabled }) {
  return (
    <Wrapper>
      {icon && <Icon src={icon} alt={name} />}
      <Input
        className={icon ? "padding" : ""}
        type={type}
        min={min || null}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      />
    </Wrapper>
  );
}

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  min: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextField;
