import React, { Suspense, useContext } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { StoreContext } from "stores/store.context";
import modalContents from "./contents/modalContents";
import Spinner from "components/common/spinner";

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  overflow: hidden;

  .centered {
    background: white;
    border-radius: 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: fit-content;
      width: fit-content;
      padding: 5rem 10rem;

      > * {
        margin: 0;
      }
    }
  }
`;

function Modal() {
  const { UiStore } = useContext(StoreContext);
  const { modalContent } = UiStore;
  const Content = modalContents[modalContent];

  return (
    <Wrapper>
      <div className="centered">
        <Suspense
          fallback={
            <div className="loader">
              <Spinner />
            </div>
          }
        >
          {modalContent.length && <Content />}
        </Suspense>
      </div>
    </Wrapper>
  );
}

export default observer(Modal);
