import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth as AmplifyAuth } from "aws-amplify";
import Auth from "auth";
import awsconfig from "config/aws-config";

/* Assets */
import passwordIcon from "assets/password_icon.svg";
import usernameIcon from "assets/username_icon.svg";

/* Components */
import { Button, TextField } from "components/common/forms";
import { ErrorMessage, SuccessMessage } from "components/common/message";

import { Form } from "./LoginForm.styled";

function LoginForm() {
  Auth.configure(awsconfig);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formDetails, setFormDetails] = useState({
    username: "",
    password: "",
  });
  const [firstLogin, setIsFirstLogin] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [changedTempPassword, setChangedTempPassword] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormDetails({ ...formDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    localStorage.clear();
    setLoading(true);

    const username = formDetails.username;
    const password = formDetails.password;

    AmplifyAuth.signIn(username, password)
      .then((user) => {
        setCognitoUser(user);
        if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
          setError(false);
          setLoading(false);
          setIsFirstLogin(true);
        } else {
          Auth.checkCredentials(username, password)
            .then((r) => {
              setError(false);
              setLoading(false);
              navigate("/otp");
            })
            .catch((e) => {
              console.log(e);
              setError(true);
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  };

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmitNewPassword = (e) => {
    e.preventDefault();

    AmplifyAuth.completeNewPassword(cognitoUser, newPassword)
      .then((r) => {
        setIsFirstLogin(false);
        setChangedTempPassword(true);
      })
      .catch((err) => console.log(err));
  };

  const renderLoginForm = () => {
    return (
      <Form>
        <TextField
          type="text"
          name="username"
          onChange={handleChange}
          value={formDetails.username}
          placeholder="Username"
          icon={usernameIcon}
        />
        <TextField
          type="password"
          name="password"
          onChange={handleChange}
          value={formDetails.password}
          placeholder="Password"
          icon={passwordIcon}
        />
        <Button
          onClick={handleSubmit}
          title={!loading ? "LOGIN" : "Processing..."}
          type="submit"
          isDisabled={loading}
        />
        {error && <ErrorMessage description="Incorrect Username or Password" />}
        {changedTempPassword && <SuccessMessage description="Password changed successfully. Login using your new password." />}
      </Form>
    );
  };

  const renderChangeTempPasswordForm = () => {
    return (
      <Form>
        <TextField
          type="password"
          name="new-password"
          onChange={handleChangeNewPassword}
          value={newPassword}
          placeholder="New Password"
          icon={passwordIcon}
        />
        <Button
          onClick={handleSubmitNewPassword}
          title={!loading ? "CHANGE PASSWORD" : "Processing..."}
          type="submit"
          isDisabled={loading}
        />
        {error && <ErrorMessage description="Incorrect Username or Password" />}
      </Form>
    );
  };

  return firstLogin ? renderChangeTempPasswordForm() : renderLoginForm();
}

export default LoginForm;
