import styled from "styled-components";

const Wrapper = styled.div`
  margin: 2rem 1.5rem;

  @media (min-width: 1366px) {
    display: flex;
    margin: 2rem;
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-right: 2rem;
`;

const AvatarWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .avatar {
    border-radius: 50%;
    margin-bottom: 1rem;
    width: 60%;
  }

  .upload {
    background: ${(props) => props.theme.colors.secondary};
    border: none;
    border-radius: 10px;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    padding: 0.5rem 0;
    width: 60%;

    &:active {
      background-color: ${(props) => props.theme.colors.secondaryActive};
    }

    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: 1024px) {
    justify-content: initial;
    margin: 0;
    width: 100%;

    .avatar {
      width: 12.5rem;
    }

    .upload {
      width: 100%;
    }
  }
`;

const FormWrapper = styled.form`
  background-color: #f8f8f8;
  border-radius: 1.25rem;
  color: #444444;
  padding: 2rem;

  .form-field {
    margin-bottom: 1.5rem;
  }

  .adtype-title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .hr {
    border: 0.5px solid #c4c4c4;
    margin: 2rem 0;
  }

  .button-container {
    margin-left: auto;
    width: 50%;

    > * {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 1366px) {
    width: 100%;

    .form-field {
      margin-bottom: 0.5rem;
      display: flex;
    }

    .button-container {
      width: 15%;
    }
  }
`;

export { Wrapper, AvatarWrapper, ImageSection, FormWrapper };
