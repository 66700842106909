import { Button, DatePicker, Label, TextField } from "components/common/forms";
import { handleFormChange } from "helpers/Form.helper";
import React, { useCallback, useContext, useState } from "react";
import { StoreContext } from "stores/store.context";
import styled from "styled-components";
import { formWrapperStyle } from "components/common/forms/FormWrapperStyle";
import { photoUploadStyle } from "../UserForm/UserForm";
import { HiOutlineUpload } from "react-icons/hi";
import { createStation, editStation } from "services/StationApi";
import { uploadPhoto } from "services/UserApi";
import { MdErrorOutline } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { getLocations } from "services/AdsApi";
import moment from "moment";

const Wrapper = styled.form`
  ${formWrapperStyle};

  .photo-upload {
    ${photoUploadStyle};
  }

  .error-message {
    display: flex;
    align-items: center;
    color: red;
    gap: 0.25rem;
    margin-left: auto;
  }

  .btn-container {
    width: 20rem;
    margin-left: auto;
  }
`;

function StationForm({ editMode }) {
  const navigate = useNavigate();

  const { id } = useParams();

  const { UserStore } = useContext(StoreContext);
  const { role_key, location_key } = UserStore?.profile;

  const emptyForm = {
    name: "",
    short_name: "",
    folder: "",
    activation_date: "",
    station_manager: "",
    media_traffic_officer: "",
  };
  const [formDetails, setFormDetails] = useState(emptyForm);
  const [stationManagerSignPhoto, setStationManagerSignPhoto] = useState(null);
  const [mtoSignPhoto, setMtoSignPhoto] = useState(null);
  const [error, setError] = useState(null);

  const fetchLocations = useCallback(async () => {
    const res = await getLocations(role_key, id);
    const {
      name,
      short_name,
      activation_date,
      folder_id,
      station_manager,
      station_manager_signature,
      media_traffic_officer,
      media_traffic_officer_signature,
    } = res[0];

    setFormDetails({
      name,
      short_name,
      folder: `https://drive.google.com/drive/u/0/folders/${folder_id}`,
      activation_date: moment(activation_date).format("YYYY-MM-DD"),
      station_manager,
      station_manager_signature,
      media_traffic_officer,
      media_traffic_officer_signature,
    });
  }, [id, role_key]);

  console.log(formDetails);

  useEffect(() => {
    if (editMode) {
      fetchLocations();
    }
  }, [editMode, fetchLocations]);

  const uploadStationManagerSignPhoto = useCallback(async () => {
    const formData = new FormData();
    formData.append("image", stationManagerSignPhoto);

    const res = await uploadPhoto(formData, role_key, location_key);

    return res.url;
  }, [location_key, role_key, stationManagerSignPhoto]);

  const uploadMtoSignPhoto = useCallback(async () => {
    const formData = new FormData();
    formData.append("image", mtoSignPhoto);

    const res = await uploadPhoto(formData, role_key, location_key);

    return res.url;
  }, [location_key, role_key, mtoSignPhoto]);

  const handleCreateStation = useCallback(async () => {
    const stationManagerSignPhotoUrl = await uploadStationManagerSignPhoto();
    const mtoSignPhotoUrl = await uploadMtoSignPhoto();

    const params = {
      ...formDetails,
      station_manager_signature: stationManagerSignPhotoUrl,
      media_traffic_officer_signature: mtoSignPhotoUrl,
    };

    const res = await createStation(params, role_key, location_key);
    if (res.error) {
      let error = res.error.split("-").join(" ");
      error = error.charAt(0).toUpperCase() + error.slice(1);
      setError(error);
    } else {
      navigate("/stations");
    }
  }, [
    uploadStationManagerSignPhoto,
    uploadMtoSignPhoto,
    formDetails,
    role_key,
    location_key,
    navigate,
  ]);

  const handleEditStation = useCallback(async () => {
    const formData = new FormData();
    formData.append("image", stationManagerSignPhoto);

    uploadPhoto(formData, role_key, location_key)
      .then((res) =>
        setFormDetails({
          ...formDetails,
          station_manager_signature: res.url,
        })
      )
      .catch((err) => console.error(err));

    const res = await editStation(formDetails, role_key, id);

    if (res.error) {
      let error = res.error.split("-").join(" ");
      error = error.charAt(0).toUpperCase() + error.slice(1);
      setError(error);
    } else {
      navigate("/stations");
    }
  }, [
    stationManagerSignPhoto,
    role_key,
    location_key,
    formDetails,
    id,
    navigate,
  ]);

  const disableSubmit = useCallback(() => {
    for (const field in formDetails) {
      if (formDetails[field]?.length < 1) return true;
    }

    if (!stationManagerSignPhoto || !mtoSignPhoto) return true;

    return false;
  }, [formDetails, mtoSignPhoto, stationManagerSignPhoto]);

  const renderError = useCallback(() => {
    if (!error) return null;

    return (
      <p className="error-message">
        <MdErrorOutline /> {error}
      </p>
    );
  }, [error]);

  const renderButton = () => {
    if (editMode) {
      return (
        <Button
          title="EDIT"
          type="button"
          onClick={handleEditStation}
          isDisabled={disableSubmit()}
        />
      );
    }

    return (
      <Button
        title="CREATE"
        type="button"
        onClick={handleCreateStation}
        isDisabled={disableSubmit()}
      />
    );
  };

  return (
    <Wrapper>
      <div className="form-section">
        <p className="form-section-title">Station Information</p>
        <div className="adform-field">
          <Label
            label="Station Name"
            sublabel="Type in station's name"
            htmlFor="name"
          />
          <TextField
            type="text"
            name="name"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.name}
          />
        </div>
        <div className="adform-field">
          <Label
            label="Station Short Name"
            sublabel="Type in station's short name"
            htmlFor="short_name"
          />
          <TextField
            type="text"
            name="short_name"
            maxLength={3}
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.short_name}
          />
        </div>
        <div className="adform-field">
          <Label
            label="Activation Date"
            sublabel="Type in station's activation date"
            htmlFor="activation_date"
          />
          <DatePicker
            name="activation_date"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.activation_date}
          />
        </div>
        <div className="adform-field">
          <Label
            label="Drive Link"
            sublabel="Type in station's drive folder link"
            htmlFor="folder"
          />
          <TextField
            type="text"
            name="folder"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.folder}
          />
        </div>
        <hr className="hr" />
      </div>

      <div className="form-section">
        <div className="adform-field">
          <Label
            label="Station Manager"
            sublabel="Type in the name of the station manager"
            htmlFor="station_manager"
          />
          <TextField
            type="text"
            name="station_manager"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.station_manager}
          />
        </div>
        <div className="adform-field">
          <Label
            label="E-Signature"
            sublabel="File types allowed: png, jpg, jpeg"
            htmlFor="stationManagerSignature"
          />
          <label className="photo-upload">
            {stationManagerSignPhoto ? (
              `File: ${stationManagerSignPhoto.name}`
            ) : (
              <>
                <HiOutlineUpload
                  style={{ marginRight: "0.5rem", fontSize: "1.25rem" }}
                />{" "}
                Upload{" "}
                {!formDetails.station_manager_signature &&
                  "(No photo uploaded)"}
              </>
            )}
            <input
              type="file"
              name="stationManagerSignature"
              accept="image/jpg, image/png, image/jpeg"
              variant="contained"
              style={{ display: "none" }}
              onChange={(e) => setStationManagerSignPhoto(e.target.files[0])}
            />
          </label>
        </div>
        <hr className="hr" />
      </div>

      <div className="form-section">
        <div className="adform-field">
          <Label
            label="Media Traffic Officer"
            sublabel="Type in the name of the media traffic officer"
            htmlFor="media_traffic_officer"
          />
          <TextField
            type="text"
            name="media_traffic_officer"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.media_traffic_officer}
          />
        </div>
        <div className="adform-field">
          <Label
            label="E-Signature"
            sublabel="File types allowed: png, jpg, jpeg"
            htmlFor="mediaTrafficOfficerSignature"
          />
          <label className="photo-upload">
            {mtoSignPhoto ? (
              `File: ${mtoSignPhoto.name}`
            ) : (
              <>
                <HiOutlineUpload
                  style={{ marginRight: "0.5rem", fontSize: "1.25rem" }}
                />{" "}
                Upload{" "}
                {!formDetails.media_traffic_officer_signature &&
                  "(No photo uploaded)"}
              </>
            )}
            <input
              type="file"
              name="stationManagerSignature"
              accept="image/jpg, image/png, image/jpeg"
              variant="contained"
              style={{ display: "none" }}
              onChange={(e) => setMtoSignPhoto(e.target.files[0])}
            />
          </label>
        </div>
        <hr className="hr" />
      </div>

      {renderError()}

      <div className="btn-container">{renderButton()}</div>
    </Wrapper>
  );
}

StationForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
};

export default StationForm;
