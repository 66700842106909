import StationForm from "components/features/StationForm";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function EditStation() {
  return (
    <AppLayout page="Edit Station">
      <StationForm editMode={true} />
    </AppLayout>
  );
}

export default EditStation;
