import styled from "styled-components";

const TimesPlayedWrapper = styled.div`
  max-width: 22.5rem;

  .times-played {
    word-break: break-word;
  }

  .on-time,
  .not-on-time {
    align-items: center;
    margin-right: 0.25rem;
    position: relative;

    &::before {
      display: inline-block;
      height: 14px;
      width: 14px;
      content: "";
      background-color: #4db287;
      border-radius: 4px;
    }
  }

  .not-on-time {
    &::before {
      background-color: #dd4f51;
    }
  }
`;

const AdStatusWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  width: 7.5rem;

  .complete {
    background-color: #4db287;
    border-radius: 12px;
    padding: 0.125rem 0;
  }

  .incomplete {
    background-color: #e1504f;
    border-radius: 12px;
    padding: 0.125rem 0;
  }

  .missed-air {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 12px;
    padding: 0.125rem 0;
  }
`;

export { TimesPlayedWrapper, AdStatusWrapper };
