import { getRoleKey } from "helpers/Role.helper";
import React, { useContext, useState } from "react";
import { StoreContext } from "stores/store.context";

import TopbarSearchBar from "./TopbarSearchBar";

function TopbarDropdownMenu() {
  const [searchField, setSearchField] = useState("client");
  const { UserStore } = useContext(StoreContext);
  const { profile } = UserStore;

  const roleKey = getRoleKey(profile.role_key);

  const renderLocationSearchOption = () => {
    if (
      roleKey !== process.env.REACT_APP_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    ) return null;

    return <option value="location">Location</option>;
  }

  return (
    <div className="dropdown-container">
      <select
        className="dropdown-field"
        onChange={(e) => setSearchField(e.target.value)}
      >
        <option value="client">Client</option>
        <option value="adType">Ad Type</option>
        <option value="schedule">Schedule</option>
        <option value="adStatus">Ad Status</option>
        {renderLocationSearchOption()}
      </select>
      <TopbarSearchBar searchField={searchField} />
    </div>
  );
}

export default TopbarDropdownMenu;
