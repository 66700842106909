import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import logout from "auth/logout";
import { Tooltip } from "react-tippy";
import { StoreContext } from "stores/store.context";
import { getRoleKey } from "helpers/Role.helper";

/* assets */
import LogoRound from "assets/logo_round.svg";
import AdScheduleIcon from "assets/adschedule_icon.svg";
import AdLogsIcon from "assets/adlogs_icon.svg";
import AccountIcon from "assets/account_icon.svg";
import SyncIcon from "assets/sync_icon.svg";
import LogoutIcon from "assets/logout_icon.svg";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const activeStyle = {
  borderLeft: "0.25rem solid #FFF",
  fontWeight: 700,
};

function Navbar({ isNavbarExpanded, setIsNavbarExpanded }) {
  const { UserStore } = useContext(StoreContext);
  const { role_key } = UserStore?.profile;

  const roleKey = getRoleKey(role_key);

  const renderSyncLogsLink = () => {
    if (
      roleKey !== process.env.REACT_APP_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    )
      return null;

    return isNavbarExpanded ? (
      <NavLink
        className="navbar__navlinks__navlink"
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        to="/stations"
      >
        <img
          className="navbar__navlinks__navlink--icon"
          src={SyncIcon}
          alt=""
        />
        Stations
      </NavLink>
    ) : (
      <Tooltip animation="scale" delay={100} title="Sync Logs">
        <NavLink
          className="navbar__navlinks__navlink"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          to="/stations"
        >
          <img
            className={
              isNavbarExpanded
                ? "navbar__navlinks__navlink--icon"
                : "navbar__navlinks__navlink--icon navbar__navlinks__navlink--icon--collapsed"
            }
            src={SyncIcon}
            alt=""
          />
        </NavLink>
      </Tooltip>
    );
  };

  const renderAccountsLink = () => {
    if (
      roleKey !== process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    )
      return null;

    return isNavbarExpanded ? (
      <NavLink
        className="navbar__navlinks__navlink"
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        to="/accounts"
      >
        <img
          className="navbar__navlinks__navlink--icon"
          src={AccountIcon}
          alt=""
        />
        Accounts
      </NavLink>
    ) : (
      <Tooltip animation="scale" delay={100} title="Accounts">
        <NavLink
          className="navbar__navlinks__navlink"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          to="/accounts"
        >
          <img
            className={
              isNavbarExpanded
                ? "navbar__navlinks__navlink--icon"
                : "navbar__navlinks__navlink--icon navbar__navlinks__navlink--icon--collapsed"
            }
            src={AccountIcon}
            alt=""
          />
        </NavLink>
      </Tooltip>
    );
  };

  return (
    <nav className={isNavbarExpanded ? "navbar" : "navbar navbar--collapsed"}>
      <img
        className={
          isNavbarExpanded
            ? "navbar__logo"
            : "navbar__logo navbar__logo--collapsed"
        }
        src={LogoRound}
        alt=""
      />
      <div
        className={
          isNavbarExpanded
            ? "navbar__navlinks"
            : "navbar__navlinks navbar__navlinks--collapsed"
        }
      >
        {isNavbarExpanded ? (
          <NavLink
            className="navbar__navlinks__navlink"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to="/"
          >
            <img
              className={
                isNavbarExpanded
                  ? "navbar__navlinks__navlink--icon"
                  : "navbar__navlinks__navlink--icon navbar__navlinks__navlink--icon-collapsed"
              }
              src={AdScheduleIcon}
              alt=""
            />
            Ad Schedule
          </NavLink>
        ) : (
          <Tooltip animation="scale" delay={100} title="Ad Schedule">
            <NavLink
              className="navbar__navlinks__navlink"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/"
            >
              <img
                className={
                  isNavbarExpanded
                    ? "navbar__navlinks__navlink--icon"
                    : "navbar__navlinks__navlink--icon navbar__navlinks__navlink--icon-collapsed"
                }
                src={AdScheduleIcon}
                alt=""
              />
            </NavLink>
          </Tooltip>
        )}
        {isNavbarExpanded ? (
          <NavLink
            className="navbar__navlinks__navlink"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to="/ad-logs"
          >
            <img
              className="navbar__navlinks__navlink--icon"
              src={AdLogsIcon}
              alt=""
            />
            Ad Logs
          </NavLink>
        ) : (
          <Tooltip animation="scale" delay={100} title="Ad Logs">
            <NavLink
              className="navbar__navlinks__navlink"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/ad-logs"
            >
              <img
                className={
                  isNavbarExpanded
                    ? "navbar__navlinks__navlink--icon"
                    : "navbar__navlinks__navlink--icon navbar__navlinks__navlink--icon--collapsed"
                }
                src={AdLogsIcon}
                alt=""
              />
            </NavLink>
          </Tooltip>
        )}
        {renderSyncLogsLink()}
        {renderAccountsLink()}
        {isNavbarExpanded ? (
          <NavLink
            className="navbar__navlinks__navlink navbar__navlinks__navlink--logout"
            onClick={logout}
            to="#"
          >
            <img
              className="navbar__navlinks__navlink--icon"
              src={LogoutIcon}
              alt=""
            />
            Logout
          </NavLink>
        ) : (
          <Tooltip
            animation="scale"
            delay={100}
            title="Logout"
            position="bottom"
          >
            <NavLink
              className="navbar__navlinks__navlink navbar__navlinks__navlink--logout"
              onClick={logout}
              to="#"
            >
              <img
                className={
                  isNavbarExpanded
                    ? "navbar__navlinks__navlink--icon"
                    : "navbar__navlinks__navlink--icon navbar__navlinks__navlink--icon--collapsed"
                }
                src={LogoutIcon}
                alt=""
              />
            </NavLink>
          </Tooltip>
        )}
        <div
          className={
            isNavbarExpanded
              ? "navbar-toggle-expanded"
              : "navbar-toggle-collapsed"
          }
          onClick={() => setIsNavbarExpanded(!isNavbarExpanded)}
        >
          {isNavbarExpanded ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              <AiOutlineArrowLeft style={{ marginRight: "0.5rem" }} /> Collapse
              Sidebar
            </span>
          ) : (
            <Tooltip
              animation="scale"
              delay={100}
              title="Expand Sidebar"
              position="auto"
            >
              <AiOutlineArrowRight />
            </Tooltip>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
