import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled(Link)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.secondary};
  border: none;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  outline: none;
  padding: 0.75rem 0;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  &:active {
    background-color: ${(props) => props.theme.colors.secondaryActive}
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1366px) {
    border-radius: 0.625rem;
    letter-spacing: 0.075rem;
    max-height: 42px;
    padding: 0.75rem 2rem;
    width: fit-content;
  }
`;

function CreateAdButton() {
  return <Wrapper to="/create-ad">Create Ad</Wrapper>;
}

export default CreateAdButton;
