import styled from "styled-components";

const AdTypesWrapper = styled.div`
  img {
    margin-right: 0.5rem;
    height: 1.125rem;
    width: auto;
  }
`;

const LocationsWrapper = styled.div``;

const AdStatusWrapper = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  width: 7.5rem;

  .active {
    background-color: #4db287;
    border-radius: 12px;
    padding: 0.125rem 0;
  }

  .inactive {
    background-color: #e1504f;
    border-radius: 12px;
    padding: 0.125rem 0;
  }

  .for-airing {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 12px;
    padding: 0.125rem 0;
  }
`;

const ActionsHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;

  .export-btn {
    cursor: pointer;
  }
`;

const ActionButtonsWrapper = styled.div`
  display: flex;

  .action-button {
    color: black;
    font-size: 1.25rem;
    margin-right: 0.125rem;

    &:hover {
      cursor: pointer;
    }
  }
`;

export {
  AdTypesWrapper,
  LocationsWrapper,
  AdStatusWrapper,
  ActionsHeaderWrapper,
  ActionButtonsWrapper,
};
