import React from "react";
import AccountForm from "components/features/AccountForm";
import AppLayout from "components/layout/AppLayout";

const Account = () => {
  return (
    <AppLayout page="User Profile">
      <AccountForm />
    </AppLayout>
  );
};

export default Account;
