import styled from "styled-components";

const Wrapper = styled.div`
  padding-bottom: 20px;

  @media only screen and (min-width: 1024px) {
    margin: 2rem 1.5rem;
  }

  .load-prev-btn,
  .load-more-btn {
    background-color: ${(props) => props.theme.colors.secondary};
    border: none;
    border-radius: 8px;
    color: white;
    font-size: 1rem;
    display: flex;
    margin: 1rem auto 0 auto;
    padding: 0.35rem 0.85rem;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background-color: ${(props) => props.theme.colors.secondaryActive};
    }
  }

  .sub-pagination-container {
    display: flex;
    gap: 0.5rem;
    margin: 0 auto;
    width: fit-content;
  }
`;

const TableWrapper = styled.table`
  border-collapase: collapse;
  border-radius: 12px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    border-radius: 0;
    display: block;
    overflow: auto;
  }
`;

const TableHeader = styled.thead`
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  font-weight: 500;

  &:nth-of-type(odd) {
    background-color: #f0f0f0;
  }

  &:nth-of-type(even) {
    background-color: #f5f5f5;
  }
`;

const HeaderCell = styled.th`
  background-color: ${(props) => props.theme.colors.secondary};
  border: none;
  padding: 1rem;
  font-size: 14px;
  text-align: left;
  width: fit-content;

  .header-cell-content {
    display: flex;

    img {
      margin-left: 0.5rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    &:first-child {
      border-top-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
    }
  }
`;

const DataCell = styled.td`
  border: none;
  padding: 0.5rem 1rem;
  text-align: left;
  min-width: 6.5rem;
`;

const TableButtons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .page-size {
    display: none;
  }

  @media only screen and (min-width: 1024px) {
    .page-size {
      align-items: center;
      display: flex;
    }
  }

  .show {
    margin-right: 0.5rem;
  }

  .dropdown {
    background-color: #f8f8f8;
    border: 1px solid #1a6698;
    border-radius: 6px;
    color: #1a6698;
    font-size: 14px;
    font-weight: 500;
    padding: 0 0.25rem;
    outline: 0;
  }
`;

const Pagination = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  width: 100%;

  button {
    align-items: center;
    background-color: ${(props) => props.theme.colors.secondary};
    border: none;
    border-radius: 6px;
    height: 1.25rem;
    line-height: 50%;
    margin: 0 0.25rem;
    padding: 0.15rem 0.25rem;
    text-align: center;
    width: 2rem;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background-color: ${(props) => props.theme.colors.secondaryActive};
      transition: 250ms ease-in-out;
    }

    &:disabled {
      background-color: ${(props) => props.theme.colors.disabled};
    }
  }

  .go-to-container {
    margin: 0 0.5rem;
  }

  .go-to-dropdown {
    margin-left: 0.5rem;
  }

  .page-number-container {
    margin-left: 0.5rem;
  }

  .page-number {
    color: #1a6698;
  }
`;

export {
  Wrapper,
  TableWrapper,
  TableHeader,
  TableBody,
  TableRow,
  HeaderCell,
  DataCell,
  Pagination,
  TableButtons,
};
