import { makeAutoObservable, runInAction } from "mobx";
import { getUsers } from "services/UserApi";
import UserStore from "./user.store";

class UiStore {
  unsyncedDates = null;
  isNavbarExpanded = true;
  isGlobalModalOpen = false;
  officers = {};
  modalContent = "";
  modalContext = {};
  syncErrors = null;
  localAdmins = null;
  nationalAdmins = null;
  localAccountsLocationId = 16;

  constructor() {
    makeAutoObservable(this);
  }

  setIsNavbarExpanded = (isExpanded) => (this.isNavbarExpanded = isExpanded);

  setIsGlobalModalOpen = (isOpen) => (this.isGlobalModalOpen = isOpen);

  setOfficers = (officers) => (this.officers = officers);

  setModalContent = (modalContent) => (this.modalContent = modalContent);

  setModalContext = (modalContext) => (this.modalContext = modalContext);

  setSyncErrors = (syncErrors) => (this.syncErrors = syncErrors);

  setUnsyncedDates = (daysNum) => (this.unsyncedDates = daysNum);

  setLocalAdmins = async () => {
    this.localAdmins = null;
    const { Users } = await getUsers(
      UserStore.profile.role_key,
      UserStore.profile.location_key || this.localAccountsLocationId,
      `["local_master_admin","local_admin"]`
    );
    runInAction(() => {
      this.localAdmins = Users.flat();
    });
  };

  setNationalAdmins = async () => {
    this.nationalAdmins = null;
    const { Users } = await getUsers(
      UserStore.profile.role_key,
      UserStore.profile.location_key,
      `["master_admin","admin"]`
    );
    runInAction(() => {
      this.nationalAdmins = Users.flat();
    });
  };

  setLocalAccountsLocationId = async (locationId) => {
    this.localAccountsLocationId = locationId;

    this.localAdmins = null;
    const { Users } = await getUsers(
      UserStore.profile.role_key,
      UserStore.profile.location_key || this.localAccountsLocationId,
      `["local_master_admin","local_admin"]`
    );
    runInAction(() => {
      this.localAdmins = Users.flat();
    });
  };
}

export default new UiStore();
