import UserForm from "components/features/UserForm/UserForm";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function CreateUser() {
  return (
    <AppLayout page="Create User">
      <UserForm editMode={false} />
    </AppLayout>
  );
}

export default CreateUser;
