import AdminAccounts from "components/features/AdminAccounts";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function Accounts() {
  return (
    <AppLayout page="Accounts">
      <AdminAccounts />
    </AppLayout>
  );
}

export default Accounts;
