import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import Table from "components/common/table";
import { getAdLogs } from "services/AdsApi";
import Spinner from "components/common/spinner/Spinner";
import { TimesPlayedWrapper, AdStatusWrapper } from "./AdViewDatatable.styled";
import { StoreContext } from "stores/store.context";

function AdViewDatatable() {
  const { id } = useParams();

  const { UserStore } = useContext(StoreContext);
  const { role_key, location_key } = UserStore?.profile;

  const [loading, setLoading] = useState(true);
  const [adLogs, setAdLogs] = useState([]);
  const [dataCount, setDataCount] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const params = {
      filterKey: "ad_id",
      filterValue: id,
    };

    getAdLogs(params, role_key, location_key).then((data) => {
      setAdLogs(data?.ads);
      setDataCount(data?.ads.length);
      setLoading(false);
    });
  }, [id, role_key, location_key]);

  const timesPlayed = (timesPlayed) => {
    if (timesPlayed.length < 1) {
      return <p>Not Played</p>;
    }

    return (
      <p className="times-played">
        {timesPlayed.map((tp, index) => {
          return (
            <React.Fragment key={index}>
              <span className={tp.status ? "on-time" : "not-on-time"} />
              {index === timesPlayed.length - 1 ? `${tp.time}` : `${tp.time}, `}
            </React.Fragment>
          );
        })}
      </p>
    );
  };

  const status = (status) => {
    const className = status?.toLowerCase().split(" ").join("-");

    return <div className={className}>{status}</div>;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Ad Type",
        accessor: "type",
      },
      {
        Header: "Product Name",
        accessor: "product_name",
      },
      {
        Header: "Media Value",
        accessor: "media_value",
      },
      {
        Header: "Date Played",
        accessor: "date_played",
      },
      {
        Header: "Ad Schedule",
        accessor: "ad_schedule",
      },
      {
        Header: "Times Played",
        accessor: "times_played",
        Cell: (tableInfo) => (
          <TimesPlayedWrapper>
            {timesPlayed(tableInfo.data[tableInfo.row.index].times_played)}
          </TimesPlayedWrapper>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (tableInfo) => (
          <AdStatusWrapper>
            {status(tableInfo.data[tableInfo.row.index].status)}
          </AdStatusWrapper>
        ),
      },
      // {
      //   Header: "Location",
      //   accessor: "location_name",
      // },
    ],
    []
  );

  const data = adLogs?.map((ad) => {
    return {
      ad_id: ad?.ad_id,
      type: ad?.type,
      product_name: ad?.product_name,
      media_value: ad?.media_value,
      date_played: ad?.date_played,
      ad_schedule: ad?.ad_schedule,
      times_played: ad?.times_played,
      status: ad?.media_value_status,
      // location_name: ad?.location.name,
    };
  });

  if (loading > 0) return <Spinner />;

  const dataDisplayed = data.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize
  );

  return (
    <Table
      columns={columns}
      data={dataDisplayed}
      dataCount={dataCount}
      pageSize={pageSize}
      setPageSize={setPageSize}
      manualPageCount={Math.ceil(dataCount / pageSize)}
      manualPageIndex={pageIndex}
      manualSetPageIndex={setPageIndex}
      manualSortBy={false}
    />
  );
}

export default AdViewDatatable;
