import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 75rem;

  .updated,
  .late {
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      display: inline-block;
      height: 14px;
      width: 14px;
      content: "";
      background-color: #4db287;
      border-radius: 4px;
      margin-right: 0.5rem;
    }
  }

  .late {
    &::before {
      background-color: #dd4f51;
    }
  }

  .btn-container {
    display: flex;
    border-top: 1px solid white;
    border-bottom: 1px solid white;

    @media only screen and (min-width: 1024px) {
      margin: 2rem 1.5rem;
      justify-content: flex-end;
      border: none;
    }

    .create-station-btn {
      background: ${(props) => props.theme.colors.secondary};
      border: none;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 600;
      padding: 0.5rem 1.25rem;
      text-decoration: none;
      width: 100%;
      text-align: center;

      @media only screen and (min-width: 1024px) {
        border-radius: 8px;
        width: fit-content;
      }

      &:active {
        background-color: ${(props) => props.theme.colors.secondaryActive};
      }

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background-color: ${(props) => props.theme.colors.disabled};
      }
    }
  }
`;

export { Wrapper };
