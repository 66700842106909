import { makeAutoObservable } from "mobx";

class UserStore {
  profile = {};
  token = null;

  constructor() {
    makeAutoObservable(this);
  }

  setProfile = (profile) => (this.profile = profile);
  setToken = (token) => (this.token = token);
}

export default new UserStore();
