import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import logout from "auth/logout";
import { StoreContext } from "stores/store.context";
import { getRoleKey } from "helpers/Role.helper";

const activeStyle = {
  backgroundColor: "#FFF",
  color: "#ED7C52",
  fontWeight: 700,
};

function MobileMenu({ isMobileMenuOpen }) {
  const style = isMobileMenuOpen
    ? "mobile--menu mobile--menu--active"
    : "mobile--menu";

  const { UserStore } = useContext(StoreContext);
  const { role_key } = UserStore?.profile;

  const roleKey = getRoleKey(role_key);

  const renderNavLinkMasterAdmin = (component) => {
    if (
      roleKey !== process.env.REACT_APP_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    )
      return null;

    return component;
  };

  const renderNavLinkLocalMasterAdmin = (component) => {
    if (
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY
    )
      return null;

    return component;
  };

  const SyncLogsLink = (
    <NavLink
      className="mobile--menu__navlinks__navlink"
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
      to="/stations"
    >
      Stations
    </NavLink>
  );

  const AccountsLink = (
    <NavLink
      className="mobile--menu__navlinks__navlink"
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
      to="/accounts"
    >
      Accounts
    </NavLink>
  );

  return (
    <div className={style}>
      <NavLink
        className="mobile--menu__navlinks__navlink"
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        to="/"
      >
        Ad Schedule
      </NavLink>
      <NavLink
        className="mobile--menu__navlinks__navlink"
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        to="/ad-logs"
      >
        Ad Logs
      </NavLink>
      {renderNavLinkMasterAdmin(SyncLogsLink)}
      {renderNavLinkLocalMasterAdmin(AccountsLink)}
      <NavLink
        className="mobile--menu__navlinks__navlink"
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        to="/account"
      >
        User Profile
      </NavLink>
      <NavLink
        className="mobile--menu__navlinks__navlink mobile--menu__navlinks__navlink--logout"
        onClick={logout}
        to="#"
      >
        Logout
      </NavLink>
    </div>
  );
}

export default MobileMenu;
