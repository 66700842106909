import { getRoleKey } from "helpers/Role.helper";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const USER_TOKEN = localStorage.getItem("token");

const createStation = async (formData, role, location) => {
  const params = {
    role: getRoleKey(role),
  };

  if (location) params.location = location;

  try {
    const response = await fetch(
      BASE_URL +
        "/dashboard/location?" +
        new URLSearchParams(params).toString(),
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + USER_TOKEN,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

const editStation = async (formData, role, location_id) => {
  const params = {
    role: getRoleKey(role),
  };

  try {
    const response = await fetch(
      BASE_URL +
        `/dashboard/location/${location_id}?` +
        new URLSearchParams(params).toString(),
      {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + USER_TOKEN,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};
const deleteStation = async (role, location) => {
  const params = {
    role: getRoleKey(role),
    location,
  };

  try {
    const response = await fetch(
      BASE_URL +
        `/dashboard/location?` +
        new URLSearchParams(params).toString(),
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + USER_TOKEN,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok === false) {
      return {
        error: "An error has occured.",
      };
    }

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

const syncLogs = async (role, location) => {
  const params = {
    role: getRoleKey(role),
  };

  if (location) params.location = location;

  try {
    const response = await fetch(
      BASE_URL + "/sync/?" + new URLSearchParams(params).toString(),
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + USER_TOKEN,
        },
      }
    );

    if (response.status === 401) {
      window.location = "/login";
    }

    if (response.status !== 200) {
      return {
        error: await response.json(),
      };
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

export { createStation, editStation, deleteStation, syncLogs };
