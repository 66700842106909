import React from "react";

/* Components */
import AppLayout from "components/layout/AppLayout";
import AdScheduleDatatable from "components/features/AdScheduleDatatable";

export default function AdSchedule() {
  return (
    <AppLayout page="Ad Schedule" showSearch={true}>
      <AdScheduleDatatable />
    </AppLayout>
  );
}
