import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

/* Components */
import MobileMenu from "components/layout/MobileMenu";

/* assets */
import LogoRound from "assets/logo_round.svg";
import MobileMenuIcon from "assets/mobile_menu.svg";
import MobileMenuCloseIcon from "assets/mobile_menu_close.svg";

function MobileNavbar({ title }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      <nav className="mobile--navbar">
        <NavLink to="/">
          <img alt="" className="mobile--navbar__logo" src={LogoRound} />
        </NavLink>
        <h1 className="mobile--navbar__title">{title}</h1>
        <img
          alt=""
          className="mobile--navbar__icon"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          src={isMobileMenuOpen ? MobileMenuCloseIcon : MobileMenuIcon}
        />
      </nav>
      <MobileMenu isMobileMenuOpen={isMobileMenuOpen} />
    </>
  );
}

export default MobileNavbar;
