/* eslint-disable react-hooks/exhaustive-deps */
import { Label, TextField } from "components/common/forms";
import React, { useEffect, useState } from "react";
import AdTypeFormSchedule from "./AdTypeFormSchedule";
import styled from "styled-components";
import AdScheduleTimeLogo from "assets/adschedule_time.svg";
import AdScheduleTimeMinusLogo from "assets/adschedule_time_minus.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  .adtype-title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  .adschedule-input-container {
    width: 100%;
  }

  .inc-dec-container {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    width: 100%;
  }

  .plus-icon,
  .minus-icon {
    height: 1.5rem;
    width: auto;

    &:hover {
      cursor: pointer;
    }
  }

  @media (min-width: 1366px) {
    .adschedule-input-container {
      width: 25rem;
    }
  }
`;

function AdTypeForm({
  name,
  handleChange,
  productName,
  mediaValue,
  schedulesCount,
  schedules,
}) {
  const [adSchedulesCount, setAdSchedulesCount] = useState(schedulesCount || 1);
  const [adSchedules, setAdSchedules] = useState([]);
  const adScheduleInput = [];

  useEffect(() => {
    handleChange("ad_schedules", name, adSchedules);
  }, [adSchedules]);

  useEffect(() => {
    const newSchedules = [];

    for (let i = 0; i < adSchedulesCount; i++) {
      schedules[i] == null
        ? newSchedules.push({ start_time: "", end_time: "" })
        : newSchedules.push(schedules[i]);
    }

    setAdSchedules(newSchedules);
  }, [adSchedulesCount]);

  for (let i = 0; i < adSchedulesCount; i++) {
    adScheduleInput.push(
      <AdTypeFormSchedule
        adSchedules={adSchedules}
        index={i}
        key={i}
        setAdSchedules={setAdSchedules}
      />
    );
  }

  return (
    <Wrapper>
      <p className="adtype-title">{name}</p>
      <div className="adform-field">
        <Label
          label="Product Name"
          sublabel="Name of the Advertisement"
        />
        <TextField
          type="text"
          name={name}
          onChange={(e) => 
            handleChange("product_name", e.target.name, e.target.value)}
          value={productName}
        />
      </div>
      <div className="adform-field">
        <Label
          label="Media Values"
          sublabel="Number of times the ad will run"
        />
        <TextField
          type="number"
          name={name}
          min={1}
          onChange={(e) =>
            handleChange("media_value", e.target.name, e.target.value)
          }
          value={mediaValue}
        />
      </div>
      <div className="adform-field">
        <Label label="Ad Schedule" sublabel="Time window the ad is played" />
        <div className="adschedule-input-container">
          {adScheduleInput}
          <div className="inc-dec-container">
            {adSchedulesCount > 1 && (
              <img
                className="minus-icon"
                src={AdScheduleTimeMinusLogo}
                alt=""
                onClick={() => setAdSchedulesCount(adSchedulesCount - 1)}
              />
            )}
            <img
              className="plus-icon"
              src={AdScheduleTimeLogo}
              alt=""
              onClick={() => setAdSchedulesCount(adSchedulesCount + 1)}
            />
          </div>
        </div>
      </div>
      <hr className="hr" />
    </Wrapper>
  );
}

export default AdTypeForm;
