import { getRoleKey } from "helpers/Role.helper";

export const getRoleLocationFilter = (role, location) => {
  const filters = {
    role: getRoleKey(role),
  };

  if (location) filters.location = location;

  return filters;
};
