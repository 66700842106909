import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 0.5rem;
  width: 20rem;
`;

const Input = styled.input`
  margin-right: 0.75rem;
`;

const Label = styled.label`
  font-size: 1.125rem;
  font-weight: 500;
`;

function Checkbox({ name, value, onChange, label, isChecked }) {

  return (
    <Wrapper>
      <Input
        checked={isChecked}
        id={value}
        name={name}
        onChange={onChange}
        type="checkbox"
        value={value}
      />
      <Label>{label}</Label>
    </Wrapper>
  );
}

Checkbox.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default Checkbox;
