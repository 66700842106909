import styled from 'styled-components';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-width: fit-content;
    max-width: 25rem;
`;

export {
    Form,
}