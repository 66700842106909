import styled from "styled-components";

const TimesPlayedWrapper = styled.div`
  max-width: 17.5rem;

  .export-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .times-played {
    word-break: break-word;
  }

  .on-time,
  .not-on-time {
    align-items: center;
    margin-right: 0.25rem;
    position: relative;

    &::before {
      display: inline-block;
      height: 14px;
      width: 14px;
      content: "";
      background-color: #4db287;
      border-radius: 4px;
    }
  }

  .not-on-time {
    &::before {
      background-color: #dd4f51;
    }
  }
`;

const ActionsHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.25rem;

  .export-btn {
    cursor: pointer;
  }
`;

export { TimesPlayedWrapper, ActionsHeaderWrapper };
