import React from "react";
import StationsDatatable from "components/features/StationsDatatable";
import AppLayout from "components/layout/AppLayout";

function Stations() {
  return (
    <AppLayout page="Stations">
      <StationsDatatable />
    </AppLayout>
  );
}

export default Stations;
