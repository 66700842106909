import React from "react";
import styled from 'styled-components';
import { TailSpin } from "react-loader-spinner";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .text {
    margin-top: 1.5rem;
  }
`

function Spinner() {
  return (
    <Wrapper>
      <TailSpin height="85" width="85" color="#1D5C87" ariaLabel="loading" />
      <p className='text'>Loading...</p>
    </Wrapper>
  );
}

export default Spinner;
