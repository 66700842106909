/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  .input-container {
    display: flex;
    justify-content: space-between;
  }

  .input {
    border: none;
    border-radius: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 1rem 0.25rem 1rem 0.5rem;
    width: 47.5%;
  }

  .plus-icon {
    display: block;
    margin-left: auto;
  }

  @media (min-width: 1366px) {
    .plus-icon {
      margin: 0;
    }
  }
`;

function AdTypeFormSchedule({ adSchedules, index, setAdSchedules }) {
  const [schedule, setSchedule] = useState({
    adScheduleIndex: index,
    start_time: "",
    end_time: "",
  });

  const handleScheduleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSchedule({ ...schedule, [name]: value });
  };

  useEffect(() => {
    const adSchedulesArray = adSchedules;

    adSchedulesArray.forEach((adSched, index) => {
      if (index === schedule.adScheduleIndex) {
        adSched.start_time = schedule.start_time;
        adSched.end_time = schedule.end_time;
      }
    });

    setAdSchedules(adSchedulesArray);
  }, [schedule]);

  useEffect(() => {
    if (adSchedules != null && adSchedules.length) {
      setSchedule({
        adScheduleIndex: index,
        start_time: adSchedules[index]?.start_time,
        end_time: adSchedules[index]?.end_time,
      });
    }
  }, [adSchedules]);

  return (
    <Wrapper>
      <div className="input-container">
        <input
          className="input"
          id="start_time"
          name="start_time"
          onChange={(e) => handleScheduleChange(e)}
          value={schedule?.start_time}
          type="time"
        />
        <input
          className="input"
          id="end_time"
          name="end_time"
          onChange={(e) => handleScheduleChange(e)}
          value={schedule?.end_time}
          type="time"
        />
      </div>
    </Wrapper>
  );
}

export default AdTypeFormSchedule;
