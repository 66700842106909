import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  max-width: 25rem;
`;

const Input = styled.input`
  border: none;
  border-radius: 1rem;
  height: 3.5rem;
  color: #1a6698;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 1.25rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.colors.text};
  }
`;

function DatePicker({ name, min, onChange, value, isDisabled, }) {
  return (
    <Wrapper>
      <Input
        type="date"
        name={name}
        min={min}
        onChange={onChange}
        value={value}
        disabled={isDisabled}
      />
    </Wrapper>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  min: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default DatePicker;
