import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./Message.styled";

const style = {
  backgroundColor: " #ffebe8",
  border: "2px solid #dd3c10",
  color: "#dd3c10",
};

function ErrorMessage({ description }) {
  return <Wrapper style={style}>{description}</Wrapper>;
}

ErrorMessage.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ErrorMessage;
