import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LocalAdmins from "./LocalAdmins";
import NationalAdmins from "./NationalAdmins";
import { AiOutlineUserAdd } from "react-icons/ai";
import { getRoleKey } from "helpers/Role.helper";
import { StoreContext } from "stores/store.context";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  margin: 1rem;

  @media only screen and (min-width: 1024px) {
    margin: 4rem auto;
    width: 50rem;
  }

  .create-user-btn {
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 1rem;
    color: white;
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.secondary};
    width: fit-content;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:active {
      background-color: ${(props) => props.theme.colors.secondaryActive};
    }
  }
`;

function AdminAccounts() {
  const {
    UserStore: { profile },
  } = useContext(StoreContext);
  const { role_key } = profile;

  const renderNationalAdmin = () => {
    if (getRoleKey(role_key) !== process.env.REACT_APP_MASTER_ADMIN_KEY)
      return null;

    return <NationalAdmins />;
  };

  return (
    <Wrapper>
      <Link to="/create-user" className="create-user-btn">
        <AiOutlineUserAdd size={20} /> Create a new user
      </Link>
      {renderNationalAdmin()}
      <LocalAdmins />
    </Wrapper>
  );
}

export default AdminAccounts;
