import { Auth } from 'aws-amplify';

const logout = async () => {
    
    try {
        Auth.signOut();
        localStorage.clear();
        window.location.replace('/login');
    } catch (error) {
        console.log('Error signing out: ', error);
    }
}

export default logout;