function handleFormChange(e, formDetails, setFormDetails) {
  const name = e.target.name;
  const type = e.target.type;
  let value = e.target.value;

  if (type === "checkbox") {
    const checked = e.target.checked;
    const checkedOptions = formDetails[name];

    if (typeof JSON.parse(value) === "object") {
      value = JSON.parse(value);

      if (checked) {
        setFormDetails({
          ...formDetails,
          [name]: [...formDetails[name], value],
        });
      } else {
        const newCheckedOptions = checkedOptions.filter(
          (option) => option.name !== value.name
        );

        setFormDetails({ ...formDetails, [name]: [...newCheckedOptions] });
      }

      return;
    }

    if (checked) {
      setFormDetails({ ...formDetails, [name]: [value, ...formDetails[name]] });
    } else {
      const newCheckedOptions = checkedOptions.filter(
        (option) => option !== value
      );

      setFormDetails({ ...formDetails, [name]: [...newCheckedOptions] });
    }

    return;
  }

  setFormDetails({ ...formDetails, [name]: value });
}

export { handleFormChange };
