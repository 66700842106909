import styled from "styled-components";
import { formWrapperStyle } from "components/common/forms/FormWrapperStyle";

const Wrapper = styled.form`
  ${formWrapperStyle}

  .publish-btn,
  .update-btn-container {
    margin-left: auto;
    margin-top: 3.75rem;
    width: 10rem;
  }

  @media (min-width: 1366px) {
    .checkbox-container {
      display: grid;
      grid-template-columns: auto auto;
      margin-bottom: 2rem;
    }

    .update-btn-container {
      display: flex;
      width: 20rem;

      > * {
        margin-left: 1rem;
      }
    }
  }
`;

export { Wrapper };
