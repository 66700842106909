import React, { useState, useEffect, useContext } from "react";
import {
  Wrapper,
  AvatarWrapper,
  FormWrapper,
  ImageSection,
} from "./AccountForm.styled";
import { Auth } from "aws-amplify";
import placeholder from "assets/avatar_placeholder.png";
import { uploadPhoto, uploadUserSignaturePhoto } from "services/UserApi";
import { Button, Label, TextField } from "components/common/forms";
import { handleFormChange } from "helpers/Form.helper";
import Spinner from "components/common/spinner/Spinner";
import { StoreContext } from "stores/store.context";
import { observer } from "mobx-react-lite";
import Swal from "sweetalert2";

const accountForm = require("./accountForm.json");

function AccountForm() {
  const { UserStore } = useContext(StoreContext);
  const { profile, setProfile } = UserStore;
  const [editMode, setEditMode] = useState(false);
  const [, setSelectedProfilePhoto] = useState(null);
  const [formDetails, setFormDetails] = useState({
    name: "",
    company_name: "",
    email: "",
    phone_number: "",
    profile_picture: "",
    signature: "",
    old_password: "",
    new_password: "",
  });

  useEffect(() => {
    if (profile.email) {
      setFormDetails({
        name: profile.name,
        company_name: profile.company_name,
        email: profile.email,
        phone_number: profile.phone_number,
        profile_picture: profile.profile_picture,
        signature: profile.signature,
      });
    }
  }, [
    profile.name,
    profile.company_name,
    profile.email,
    profile.phone_number,
    profile.profile_picture,
    profile.signature,
  ]);

  const updateStoreProfile = async () => {
    await Auth.currentUserInfo().then((data) => {
      setProfile({
        name: data?.attributes["custom:Name"],
        company_name: data?.attributes["custom:company_name"],
        phone_number: data?.attributes["custom:phone_number"],
        profile_picture: data?.attributes["custom:profile_picture"],
        signature: data?.attributes["custom:signature"],
        email: data?.attributes.email,
        role_key: data?.attributes["custom:role"],
        location_key: data?.attributes["custom:location"],
      });
    });
  };

  const handleProfilePhotoUpload = async (e) => {
    const reader = new FileReader();
    const formData = new FormData();
    const user = await Auth.currentAuthenticatedUser();

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      const image = e.target.files[0];
      setSelectedProfilePhoto(image);

      formData.append("image", image);
      const res = await uploadPhoto(
        formData,
        profile.role_key,
        profile.location_key
      );
      setFormDetails({ ...formDetails, profile_picture: res?.url });

      await Auth.updateUserAttributes(user, {
        "custom:profile_picture": res?.url,
      })
        .then(() => updateStoreProfile())
        .catch((err) => console.log(err));
    }
  };

  const handleSignaturePhotoUpload = async (e) => {
    const reader = new FileReader();
    const formData = new FormData();
    const user = await Auth.currentAuthenticatedUser();

    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      const image = e.target.files[0];
      setSelectedProfilePhoto(image);

      formData.append("image", image);
      const res = await uploadUserSignaturePhoto(
        formData,
        profile.role_key,
        profile.location_key
      );
      setFormDetails({ ...formDetails, signature: res?.url });

      await Auth.updateUserAttributes(user, {
        "custom:signature": res?.url,
      })
        .then(() => updateStoreProfile())
        .catch((err) => console.log(err));
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let user = await Auth.currentAuthenticatedUser();

    const updateUserAttributes = async () => {
      await Auth.updateUserAttributes(user, {
        "custom:Name": formDetails.name,
        "custom:company_name": formDetails.company_name,
        email: formDetails.email,
        "custom:phone_number": formDetails.phone_number,
      })
        .then(() => {
          setEditMode(false);
          updateStoreProfile();
        })
        .catch((err) => console.log(err));
    };

    if (
      formDetails.old_password?.length > 0 &&
      formDetails.new_password?.length > 0
    ) {
      updateUserAttributes();
      await Auth.changePassword(
        user,
        formDetails.old_password,
        formDetails.new_password
      )
        .then((data) => data)
        .catch((err) => {
          Swal.fire(
            "Something went wrong!",
            "Password was not updated.",
            "error"
          );
        });
    } else {
      updateUserAttributes();
    }
  };

  const formSectionFields = (fields) => {
    return (
      <div>
        {fields.map((field) => {
          return (
            <div className="form-field" key={field.name}>
              <Label label={field.label} sublabel={field.sublabel || ""} />
              <TextField
                name={field.name}
                type={field.type}
                onChange={(e) =>
                  handleFormChange(e, formDetails, setFormDetails)
                }
                placeholder={formDetails[field.name]}
                value={formDetails[field.name]}
                disabled={!editMode}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const passwordFields = () => {
    if (!editMode) return;

    return (
      <div>
        <p className="adtype-title">Password Management</p>
        <div className="form-field">
          <Label label="Old Password" sublabel="Type in your old password" />
          <TextField
            name="old_password"
            type="password"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.old_password}
          />
        </div>
        <div className="form-field">
          <Label label="New Password" sublabel="Type in your new password" />
          <TextField
            name="new_password"
            type="password"
            onChange={(e) => handleFormChange(e, formDetails, setFormDetails)}
            value={formDetails.new_password}
          />
        </div>
        <hr className="hr" />
      </div>
    );
  };

  const formButtons = () => {
    if (editMode) {
      return (
        <div className="button-container">
          <Button title="UPDATE" onClick={submitForm} type="button" />
        </div>
      );
    }

    return (
      <div className="button-container">
        <Button title="EDIT" type="button" onClick={() => setEditMode(true)} />
      </div>
    );
  };

  if (!profile.email) return <Spinner />;

  return (
    <Wrapper>
      <ImageSection>
        <AvatarWrapper>
          <img
            alt="avatar"
            className="avatar"
            src={profile.profile_picture || placeholder}
          />
          <label className="upload">
            Upload
            <input
              type="file"
              accept="image/jpg , image/png , image/jpeg"
              variant="contained"
              style={{ display: "none" }}
              onChange={handleProfilePhotoUpload}
            />
          </label>
        </AvatarWrapper>
        <AvatarWrapper>
          <label className="upload">
            Upload Signature
            <input
              type="file"
              accept="image/jpg , image/png , image/jpeg"
              variant="contained"
              style={{ display: "none" }}
              onChange={handleSignaturePhotoUpload}
            />
          </label>
        </AvatarWrapper>
      </ImageSection>
      <FormWrapper>
        {accountForm.formSections.map((section) => {
          return (
            <div key={section.title}>
              <p className="adtype-title">{section.title}</p>
              {formSectionFields(section.fields)}
              <hr className="hr" />
            </div>
          );
        })}
        {passwordFields()}
        {formButtons()}
      </FormWrapper>
    </Wrapper>
  );
}

export default observer(AccountForm);
