import StationForm from "components/features/StationForm";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function CreateStation() {
  return (
    <AppLayout page="Create a New Station">
      <StationForm editMode={false} />
    </AppLayout>
  );
}

export default CreateStation;
