import AdViewDatatable from "components/features/AdViewDatatable";
import AdViewDetails from "components/features/AdViewDetails";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function AdView() {
  return (
    <AppLayout page="Ad View">
      <AdViewDetails />
      <AdViewDatatable />
    </AppLayout>
  );
}

export default AdView;
