import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;

  .label {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .sub-label {
    display: none;
    margin-top: 0.25rem;
  }

  @media (min-width: 1366px) {
    min-width: 20rem;
    margin-bottom: 0rem;

    .sub-label {
      display: initial;
    }
  }
`;

function Label({ label, sublabel, htmlFor }) {
  return (
    <Wrapper>
      <label className="label" htmlFor={htmlFor}>
        {label}
      </label>
      <small className="sub-label">{sublabel}</small>
    </Wrapper>
  );
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
  sublabel: PropTypes.string,
};

export default Label;
