import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { StoreContext } from "stores/store.context";

function Location({ value, handleChange, name }) {
  const { AdsStore } = useContext(StoreContext);
  const { locations } = AdsStore;

  return (
    <select
      className="search-field select-field"
      onChange={handleChange}
      value={value}
      name={name}
    >
      <option value="" disabled hidden selected>
        Location
      </option>
      {locations?.map((loc) => (
        <option value={loc.location_id} key={loc.location_id}>
          {loc.name}
        </option>
      ))}
    </select>
  );
}

export default observer(Location);
