import React, { memo } from "react";

/* Component */
import AppLayout from "components/layout/AppLayout";
import AdForm from "components/features/AdForm";

function EditAd() {
  return (
    <AppLayout page="Edit Ad">
      <AdForm editMode={true} />
    </AppLayout>
  );
}

export default memo(EditAd);
