import React, { useState } from "react";
import OtpInput from "react-otp-input-rc-17";
import auth from "auth";
import { Form } from "./OtpForm.styled";

/* Components */
import { ErrorMessage, SuccessMessage } from "components/common/message";
import { Button, GhostButton } from "components/common/forms";

function OtpForm() {
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);
  const [validating, setValidating] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  const isDisabled = !(resendTimer < 1);

  const otpResendTimer = setTimeout(() => {
    setResendTimer(resendTimer - 1);
  }, 1000);

  resendTimer < 1 && clearTimeout(otpResendTimer);

  const handleResendClick = () => {
    auth.resendOTP();
    setOtp("");
    setError(false);
    setResend(true);
    setResendTimer(60);
  };

  const handleChange = (value) => setOtp(value);

  const handleSubmit = (e) => {
    e.preventDefault();

    setValidating(true);

    auth
      .verifyOTP(otp)
      .then((data) => {
        localStorage.setItem("token", data.accessToken.jwtToken);
        localStorage.setItem("showSyncNotif", true);
        clearTimeout(otpResendTimer);
        setError(false);
        setResend(false);
        window.location = "/";
      })
      .catch(() => {
        setValidating(false);
        setError(true);
        setResend(false);
        setOtp("");
      });
  };

  const showError = () => {
    if (error) {
      return <ErrorMessage description="You have entered the wrong pin" />;
    }
  };

  const showResend = () => {
    if (resend) {
      return <SuccessMessage description="A new OTP has been sent" />;
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h1 className="title">Please enter the OTP to proceed</h1>
      <p className="subtitle">
        A one time password (OTP) has been sent to your email.
      </p>
      <OtpInput
        className="otp-input"
        isInputNum={true}
        numInputs={6}
        onChange={handleChange}
        value={otp}
      />
      <Button
        type="submit"
        title={!validating ? "VALIDATE OTP" : "Processing..."}
        isDisabled={validating}
      />
      <GhostButton
        onClick={handleResendClick}
        type="button"
        title={resendTimer ? `RESEND OTP (${resendTimer})` : "RESEND OTP"}
        isDisabled={isDisabled}
      />
      {showError()}
      {showResend()}
    </Form>
  );
}

export default OtpForm;
