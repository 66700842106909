import React, { memo } from "react";

/* Component */
import AppLayout from "components/layout/AppLayout";
import AdForm from "components/features/AdForm";

function DuplicateAd() {
  return (
    <AppLayout page="Duplicate Ad">
      <AdForm duplicate={true}/>
    </AppLayout>
  );
}

export default memo(DuplicateAd);
