import React, { useEffect, useContext, useCallback, useState } from "react";
import Table from "components/common/table";
import { StoreContext } from "stores/store.context";
import {
  MdOutlineRemoveRedEye,
  MdOutlineDeleteOutline,
  MdOutlineFileCopy,
} from "react-icons/md";
import { TbPencil } from "react-icons/tb";
import { GrDocumentPdf } from "react-icons/gr";
import { deleteAd } from "services/AdsApi";
import {
  AdTypesWrapper,
  LocationsWrapper,
  AdStatusWrapper,
  ActionButtonsWrapper,
  ActionsHeaderWrapper,
} from "./AdScheduleDatatable.styled";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import sponsorshipIcon from "assets/sponsorship_icon.svg";
import announcementIcon from "assets/announcement_icon.svg";
import runningAdIcon from "assets/runningad_icon.svg";
import timeCheckIcon from "assets/timecheck_icon.svg";
import blockTimeIcon from "assets/blocktime_icon.svg";
import { observer } from "mobx-react-lite";
import { exportAds } from "services/AdsApi";
import exportImage from "assets/export_button.png";
import Spinner from "components/common/spinner";
import { Tooltip } from "react-tippy";
import { getRoleKey } from "helpers/Role.helper";

function AdScheduleDatatable() {
  const { AdsStore, UserStore, UiStore } = useContext(StoreContext);
  const { setIsGlobalModalOpen, setModalContent, setModalContext } = UiStore;
  const { role_key, location_key } = UserStore?.profile;
  const { ads, adCount, adQueryParams, loading, storeGetAds, queryExport } =
    AdsStore;

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState({
    direction: null,
    accessor: null,
  });

  const roleKey = getRoleKey(role_key);
  const isAuthorized =
    roleKey === process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY ||
    roleKey === process.env.REACT_APP_MASTER_ADMIN_KEY;

  const columnHeaderClick = async (column) => {
    if (column.id === "actions") return;

    switch (column.sortDirection) {
      case "DESC":
        setSort({ direction: null, accessor: null });
        await storeGetAds(
          {
            ...adQueryParams,
            order: "desc",
            orderBy: "ad_id",
          },
          role_key,
          location_key
        );
        break;
      case "ASC":
        setSort({ direction: "DESC", accessor: column.id });
        await storeGetAds(
          {
            ...adQueryParams,
            order: "desc",
            orderBy: column.id,
          },
          role_key,
          location_key
        );
        break;
      default:
        setSort({ direction: "ASC", accessor: column.id });
        await storeGetAds(
          {
            ...adQueryParams,
            order: "asc",
            orderBy: column.id,
          },
          role_key,
          location_key
        );
        break;
    }
  };

  useEffect(() => {
    const fetchAds = async () => {
      await storeGetAds(
        { ...adQueryParams, offset: page * limit },
        role_key,
        location_key
      );
    };

    if (role_key) {
      fetchAds();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, role_key, location_key]);

  const adTypes = (types) => {
    const tooltipContent = () => (
      <ul style={{ listStyleType: "none" }}>
        {types.map((type) => (
          <li key={type}>{type}</li>
        ))}
      </ul>
    );

    let comp = [];

    if (types.includes("Radio Commercials"))
      comp.push(
        <img key="radio_commercials" src={runningAdIcon} alt="radio_commercial-icon" />
      );
    if (types.includes("Time Check"))
      comp.push(
        <img key="time_check" src={timeCheckIcon} alt="time-check-icon" />
      );
    if (types.includes("Song/News Sponsorship"))
      comp.push(
        <img key="sponsorship" src={sponsorshipIcon} alt="sponsorship-icon" />
      );
    if (types.includes("Announcers on Board"))
      comp.push(
        <img
          key="announcement"
          src={announcementIcon}
          alt="announcement-icon"
        />
      );
    if (types.includes("Block Time"))
      comp.push(
        <img key="block-time" src={blockTimeIcon} alt="block-time" />
      );

    return (
      <Tooltip
        animation="scale"
        delay={100}
        html={tooltipContent()}
        position="bottom"
      >
        {comp}
      </Tooltip>
    );
  };

  const adLocations = (locations) => {
    const text = locations.length > 1 ? "Locations" : "Location";

    const tooltipContent = () => (
      <ul style={{ listStyleType: "none" }}>
        {locations.map((loc) => (
          <li key={loc.name}>{loc.name}</li>
        ))}
      </ul>
    );

    return (
      <Tooltip
        animation="scale"
        delay={100}
        html={tooltipContent()}
        position="bottom"
      >
        {locations.length} {text}
      </Tooltip>
    );
  };

  const adStatus = (status) => {
    const className = status.toLowerCase().split(" ").join("-");

    return <div className={className}>{status}</div>;
  };

  const handleGenerateReport = useCallback(
    (ad_id) => {
      setIsGlobalModalOpen(true);
      setModalContent("generateAdReport");
      setModalContext({
        ad_id,
      });
    },
    [setIsGlobalModalOpen, setModalContent, setModalContext]
  );

  const handleDelete = useCallback(
    (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this ad schedule?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#ED7C52",
        cancelButtonColor: "#1D5C87",
        reverseButtons: true,
      }).then((res) => {
        if (res.isConfirmed) {
          deleteAd(id, role_key, location_key).then((r) => {
            if (r.error) {
              Swal.fire("Error!", "Something went wrong", "error");
              return;
            }
            Swal.fire("Ad deleted!", "Ad is successfully deleted", "success");
            window.location.replace("/");
          });
        }
        return;
      });
    },
    [role_key, location_key]
  );

  const exportedData = useCallback(() => {
    const params = new URLSearchParams(queryExport);
    const query = params.toString();
    exportAds(query, role_key, location_key);
  }, [queryExport, role_key, location_key]);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID #",
        accessor: "ad_id",
        sortDirection: sort.accessor === "ad_id" ? sort.direction : "none",
      },
      {
        Header: "Client",
        accessor: "client",
        sortDirection: sort.accessor === "client" ? sort.direction : "none",
      },
      {
        Header: "Ad Type",
        accessor: "types",
        Cell: (tableInfo) => (
          <AdTypesWrapper>
            {adTypes(tableInfo.data[tableInfo.row.index].types)}
          </AdTypesWrapper>
        ),
        sortDirection: sort.accessor === "types" ? sort.direction : "none",
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        sortDirection: sort.accessor === "start_date" ? sort.direction : "none",
      },
      {
        Header: "End Date",
        accessor: "end_date",
        sortDirection: sort.accessor === "end_date" ? sort.direction : "none",
      },
      {
        Header: "Location",
        accessor: "locations",
        Cell: (tableInfo) => (
          <LocationsWrapper>
            {adLocations(tableInfo.data[tableInfo.row.index].locations)}
          </LocationsWrapper>
        ),
        sortDirection: sort.accessor === "locations" ? sort.direction : "none",
      },
      {
        Header: "Ad Status",
        accessor: "status",
        Cell: (tableInfo) => (
          <AdStatusWrapper>
            {adStatus(tableInfo.data[tableInfo.row.index].status)}
          </AdStatusWrapper>
        ),
        sortDirection: sort.accessor === "status" ? sort.direction : "none",
      },
      {
        Header: () => (
          <ActionsHeaderWrapper>
            <div>Actions</div>
            <div onClick={exportedData}>
              <img
                className="export-btn"
                src={exportImage}
                style={{ height: "18px", marginTop: "5px" }}
                alt="export"
              />
            </div>
          </ActionsHeaderWrapper>
        ),
        accessor: "actions",
        disableSortBy: true,
        Cell: (tableInfo) => (
          <ActionButtonsWrapper>
            <Link
              className="action-button"
              to={`/ad/${tableInfo.data[tableInfo.row.index].ad_id}`}
            >
              <MdOutlineRemoveRedEye />
            </Link>
            {isAuthorized && (
              <>
                <Link
                  className="action-button"
                  to={`/edit-ad/${tableInfo.data[tableInfo.row.index].ad_id}`}
                >
                  <TbPencil />
                </Link>
                <GrDocumentPdf
                  className="action-button"
                  onClick={() =>
                    handleGenerateReport(
                      tableInfo.data[tableInfo.row.index].ad_id
                    )
                  }
                />
                <Link
                  className="action-button"
                  to={`/duplicate-ad/${
                    tableInfo.data[tableInfo.row.index].ad_id
                  }`}
                >
                  <MdOutlineFileCopy />
                </Link>
                <MdOutlineDeleteOutline
                  className="action-button"
                  onClick={() =>
                    handleDelete(tableInfo.data[tableInfo.row.index].ad_id)
                  }
                />
              </>
            )}
          </ActionButtonsWrapper>
        ),
      },
    ],
    [
      sort.accessor,
      sort.direction,
      exportedData,
      isAuthorized,
      handleGenerateReport,
      handleDelete,
    ]
  );

  const data = ads?.map((ad) => {
    return {
      ad_id: ad?.ad_id,
      client: ad?.client,
      types: ad?.types.map((type) => type.name),
      start_date: ad?.start_date,
      end_date: ad?.end_date,
      locations: ad?.locations,
      status: ad?.status,
    };
  });

  if (loading) return <Spinner />;

  return (
    <>
      <Table
        columns={columns}
        data={data}
        dataCount={adCount}
        onHeaderClick={columnHeaderClick}
        manualPageIndex={page}
        manualSetPageIndex={setPage}
        pageSize={limit}
        setPageSize={setLimit}
        manualPageCount={Math.ceil(adCount / limit)}
      />
    </>
  );
}

export default observer(AdScheduleDatatable);
