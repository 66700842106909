import UserForm from "components/features/UserForm/UserForm";
import AppLayout from "components/layout/AppLayout";
import React from "react";

function EditUser() {
  return (
    <AppLayout page="Update User">
      <UserForm editMode={true} />
    </AppLayout>
  );
}

export default EditUser;
