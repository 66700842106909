import { css } from "styled-components";
import downArrow from "assets/down_arrow_grey.svg";

export const formWrapperStyle = css`
  background-color: #f8f8f8;
  border-radius: 1.25rem;
  color: #444444;
  margin: 2rem 1.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .form-section-title {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  .hr {
    border: 0.5px solid #c4c4c4;
    margin-top: 2rem;
  }

  .dropdown-field {
    display: flex;
    font-size: 1rem;
    outline: none;
    padding-left: 1.125rem;
    max-width: 25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    height: 3.5rem;
    border-radius: 1rem;
    background-color: #fff;
    background-image: url(${downArrow});
    background-position-x: 92.5%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    border: none;
    color: #1d5c87;
    font-weight: 700;
    padding-right: 0.375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  @media (min-width: 1366px) {
    .adform-field {
      display: flex;
    }
`;
