import styled from "styled-components";

const Wrapper = styled.p`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 0.875rem;
  height: 3.5rem;
  justify-content: center;
  text-align: center;
  max-width: 400px;
`;

export { Wrapper };
