import React from "react";

function AdStatus({ value, handleChange }) {
  return (
    <select
      className="search-field select-field"
      onChange={handleChange}
      defaultValue=""
      name="status"
    >
      <option value="" disabled hidden>
        Ad Status
      </option>
      <option value="Active">Active</option>
      <option value="Inactive">Inactive</option>
      <option value="For Airing">For Airing</option>
    </select>
  );
}

export default AdStatus;
