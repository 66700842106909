import React from "react";
import { Wrapper } from "./Login.styled";

/* Components */
import LoginForm from "components/features/LoginForm";

export default function Login() {
  return (
    <Wrapper>
      <LoginForm/>
    </Wrapper>
  );
}
