import React from "react";
import { Wrapper } from "views/Login/Login.styled";

/* Components */
import OtpForm from "components/features/OtpForm";

/* OTP page is using the same style component as Login page */

export default function Otp() {
  return (
    <Wrapper>
      <OtpForm />
    </Wrapper>
  );
}
