import awsconfig from "config/aws-config";

const AWS = require("aws-sdk");
AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: awsconfig.aws_cognito_region,
})

export async function getUser(Username) {
  const cognito = new AWS.CognitoIdentityServiceProvider();

  const res = await cognito.adminGetUser({
    UserPoolId: awsconfig.aws_user_pools_id,
    Username,
  }).promise();

  if (res) {
    const { $response: { httpResponse, error } } = res;
    
    if (httpResponse.statusCode === 200) {
      return res;
    }

    if (error) {
      throw Error(error);
    }
  }

  return null;
}
export async function updateUser(Username, formDetails) {
  const cognito = new AWS.CognitoIdentityServiceProvider();

  const UserAttributes = [
    {
      "Name": "email",
      "Value": formDetails.email,
    },
    {
      "Name": "custom:Name",
      "Value": `${formDetails.firstName} ${formDetails.lastName}`,
    },
    {
      "Name": "custom:phone_number",
      "Value": formDetails.phoneNumber,
    },
    {
      "Name": "custom:role",
      "Value": formDetails.role,
    },
    {
      "Name": "custom:location",
      "Value": formDetails.location,
    },
    {
      "Name": "custom:profile_picture",
      "Value": formDetails.profilePicture,
    },
  ];

  const res = await cognito.adminUpdateUserAttributes({
    UserAttributes,
    UserPoolId: awsconfig.aws_user_pools_id,
    Username,
  }).promise();

  if (res) {
    const { $response: { httpResponse, error } } = res;
    
    if (httpResponse.statusCode === 200) {
      return true;
    }

    if (error) {
      throw Error(error);
    }
  }

  return null;
}

export async function deleteUser(Username) {
  const cognito = new AWS.CognitoIdentityServiceProvider();

  const res = await cognito.adminDeleteUser({
    UserPoolId: awsconfig.aws_user_pools_id,
    Username,
  }).promise();

  if (res) {
    const { $response: { httpResponse, error } } = res;
    
    if (httpResponse.statusCode === 200) {
      return true;
    }

    if (error) {
      throw Error(error);
    }
  }

  return null;
}
