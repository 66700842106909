import React, { useCallback, useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { StoreContext } from "stores/store.context";
import { getRoleKey } from "helpers/Role.helper";

/* Views */
import PrivateRoute from "components/routes/PrivateRoute";
import AdLogs from "views/AdLogs";
import AdSchedule from "views/AdSchedule";
import Login from "views/Login";
import Otp from "views/Otp";
import Account from "views/Account";
import CreateAd from "views/CreateAd";
import EditAd from "views/EditAd";
import AdView from "views/AdView";
import DuplicateAd from "views/DuplicateAd";
import Stations from "views/Stations";
import Accounts from "views/Accounts";
import CreateUser from "views/CreateUser";
import UpdateUser from "views/UpdateUser";
import CreateStation from "views/CreateStation";
import EditStation from "views/EditStation";

const NATIONAL_MASTER_ADMIN_ROUTES = ["/create-station", "/edit-station"];
const NATIONAL_ADMIN_ROUTES = ["/stations"];
const MASTER_ADMIN_ROUTES = ["/create-user", "/update-user", "/accounts"];

function PageRoutes() {
  const { UserStore } = useContext(StoreContext);
  const { profile } = UserStore;
  const { role_key } = profile;

  const roleKey = useCallback(() => getRoleKey(role_key), [role_key]);

  const isRouteAccessible = useCallback(() => {
    const pathname = window.location.pathname;

    if (
      NATIONAL_MASTER_ADMIN_ROUTES.includes(pathname) &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    )
      return false;

    if (
      NATIONAL_ADMIN_ROUTES.includes(pathname) &&
      roleKey !== process.env.REACT_APP_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    )
      return false;

    if (
      MASTER_ADMIN_ROUTES.includes(pathname) &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY
    )
      return false;

    return true;
  }, [roleKey]);

  useEffect(() => {
    if (!isRouteAccessible()) {
      window.location.replace("/");
    }
  }, [isRouteAccessible]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/otp" element={<Otp />} />
      <Route exact element={<PrivateRoute />}>
        <Route exact path="/" element={<AdSchedule />} />
        <Route exact path="/create-ad" element={<CreateAd />} />
        <Route exact path="/edit-ad/:id" element={<EditAd />} />
        <Route exact path="/duplicate-ad/:id" element={<DuplicateAd />} />
        <Route exact path="/ad/:id" element={<AdView />} />
        <Route exact path="/ad-logs" element={<AdLogs />} />
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/accounts" element={<Accounts />} />
        <Route exact path="/create-user" element={<CreateUser />} />
        <Route exact path="/update-user/:id" element={<UpdateUser />} />
        <Route exact path="/stations" element={<Stations />} />
        <Route exact path="/create-station" element={<CreateStation />} />
        <Route exact path="/edit-station/:id" element={<EditStation />} />
        <Route path="*" element={<AdSchedule />} />
      </Route>
    </Routes>
  );
}

export default PageRoutes;
