import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import awsconfig from "config/aws-config";
import { Auth } from "aws-amplify";
import PageRoutes from "components/routes/PageRoutes";
import GlobalStyle from "GlobalStyles";

/* Stylesheet */
import Theme from "./Theme";
import "styles/css/index.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  Auth.configure(awsconfig);

  return (
    <Theme>
      <GlobalStyle />
      <Router>
        <PageRoutes />
      </Router>
    </Theme>
  );
}

export default App;
