import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./Message.styled";

const style = {
  backgroundColor: " #def2d6",
  border: "2px solid #586f52",
  color: "#586f52",
};

function SuccessMessage({ description }) {
  return <Wrapper style={style}>{description}</Wrapper>;
}

SuccessMessage.propTypes = {
  description: PropTypes.string.isRequired,
};

export default SuccessMessage;
