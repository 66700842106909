import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAd } from "services/AdsApi";
import { StoreContext } from "stores/store.context";
import styled from "styled-components";

const AdViewDetailsWrapper = styled.div`
  background-color: #f8f8f8;
  border-radius: 12px;
  margin: 2rem 1.5rem;
  padding: 1.5rem 3rem;

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;

    @media only screen and (min-width: 1024px) {
      flex-direction: row;
      gap: 0;
      margin-bottom: 1.5rem;
    }
  }

  .info {
    margin-right: 3.5rem;

    small {
      font-size: 12px;
    }

    > p,
    li {
      list-style: none;
      font-size: 1.125rem;
      font-weight: 500;
      margin-top: 0.25rem;
    }
  }

  hr {
    margin-bottom: 1.5rem;
  }

  .adtype-title {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
    text-transform: uppercase;
  }
`;

function AdViewDetails() {
  const { id } = useParams();

  const { UserStore } = useContext(StoreContext);
  const { role_key, location_key } = UserStore?.profile;

  const [ad, setAd] = useState({});

  useEffect(() => {
    if (role_key) {
      getAd(id, role_key, location_key).then((data) => {
        setAd(data?.ads[0]);
      });
    }
  }, [id, role_key, location_key]);

  return (
    <AdViewDetailsWrapper>
      <div className="info-container">
        <div className="info">
          <small>Client</small>
          <p>{ad?.client || "N/A"}</p>
        </div>
        <div className="info">
          <small>Start Date</small>
          <p>{ad?.start_date || "N/A"}</p>
        </div>
        <div className="info">
          <small>End Date</small>
          <p>{ad?.end_date || "N/A"}</p>
        </div>
      </div>
      <div className="info-container">
        <div className="info">
          <small>Locations</small>
          <ul>
            {ad?.locations?.map((loc) => (
              <li key={loc.name}>{loc.name}</li>
            ))}
          </ul>
        </div>
        <div className="info">
          <small>Ad Types</small>
          <ul>
            {ad?.types?.map((type) => (
              <li key={type.name}>{type.name}</li>
            ))}
          </ul>
        </div>
        <div className="info">
          <small>Ad Status</small>
          <p>{ad?.status}</p>
        </div>
      </div>
    </AdViewDetailsWrapper>
  );
}

export default AdViewDetails;
