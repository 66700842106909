import React from "react";

/* Components */
import AppLayout from "components/layout/AppLayout";
import AdLogsDatatable from "components/features/AdLogsDatatable";

function AdLogs() {
  return (
    <AppLayout page="Ad Logs" showSearch={true}>
      <AdLogsDatatable />
    </AppLayout>
  );
}

export default AdLogs;
