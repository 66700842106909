import React, { useContext, useEffect, useState } from "react";
import { getAdTypes } from "services/AdsApi";
import { StoreContext } from "stores/store.context";

function AdType({ value, handleChange, name }) {
  const { UserStore } = useContext(StoreContext);

  const { profile } = UserStore;
  const roleKey = profile.role_key;
  const location = profile.location_key;

  const [adTypes, setAdTypes] = useState([]);

  useEffect(() => {
    getAdTypes(roleKey, location).then((data) => setAdTypes(data));
  }, [roleKey, location]);

  return (
    <select
      className="search-field select-field"
      onChange={handleChange}
      name={name}
      value={value}
    >
      <option value="" disabled hidden selected>
        Ad Type
      </option>
      {adTypes?.map((type) => (
        <option key={type.id} value={type.id}>
          {type.value}
        </option>
      ))}
    </select>
  );
}

export default AdType;
