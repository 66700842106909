import { getRoleKey } from "helpers/Role.helper";
import { getRoleLocationFilter } from "./services.utils";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const USER_TOKEN = localStorage.getItem("token");

const uploadPhoto = async (formData, role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl = "/profile/image/?" + new URLSearchParams(params).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
      body: formData,
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

const uploadUserSignaturePhoto = async (formData, role, location) => {
  const params = getRoleLocationFilter(role, location);

  let queryUrl =
    "/profile/signature/?" + new URLSearchParams(params).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
      body: formData,
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

const createUser = async (formData, role, location) => {
  const params = {
    role: getRoleKey(role),
  };

  if (location) {
    params.location = location;
  }

  let queryUrl = "/profile/user/?" + new URLSearchParams(params).toString();

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

const getUsers = async (role, location, roleFilter) => {
  const params = {
    role: getRoleKey(role),
  };

  if (location) {
    params.location = location;
  }

  let queryUrl =
    "/profile/user/?" +
    new URLSearchParams(params).toString() +
    "&roleFilter=" +
    roleFilter;

  try {
    const response = await fetch(BASE_URL + queryUrl, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + USER_TOKEN,
      },
    });

    if (response.status === 401) {
      window.location = "/login";
    }

    return await response.json();
  } catch (e) {
    /* do nothing */
  }
};

export { createUser, getUsers, uploadPhoto, uploadUserSignaturePhoto };
