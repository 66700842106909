import React, { useCallback, useContext, useEffect, useState } from "react";
import { StoreContext } from "stores/store.context";
import AdminCard from "./AdminCard";
import styled from "styled-components";
import { adminAccountsStyle } from "./NationalAdmins";
import { getLocations } from "services/AdsApi";
import downArrow from "assets/down_arrow_grey.svg";
import { getRoleKey } from "helpers/Role.helper";
import { observer } from "mobx-react-lite";

const Wrapper = styled.div`
  ${adminAccountsStyle};

  .locations-dropdown {
    display: flex;
    font-size: 1rem;
    outline: none;
    padding-left: 2rem;
    max-width: 25rem;
    width: 24.5rem;
    height: 3.5rem;
    border-radius: 1rem;
    background-color: #fff;
    background-image: url(${downArrow});
    background-position-x: 92.5%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    border: 1px solid ${(props) => props.theme.colors.primary};
    font-weight: 500;
    text-transform: uppercase;
    padding-right: 0.375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .location-name {
    margin-bottom: 2rem;
  }
`;

function LocalAdmins() {
  const {
    UiStore,
    UserStore: { profile },
  } = useContext(StoreContext);
  const { role_key, location_key } = profile;
  const { localAdmins, setLocalAdmins, localAccountsLocationId, setLocalAccountsLocationId } = UiStore;

  const [locationOptions, setLocationOptions] = useState(null);

  useEffect(() => {
    if (localAdmins == null) {
      setLocalAdmins(role_key, location_key);
    }
  }, [role_key, location_key, localAdmins, setLocalAdmins]);

  const fetchLocations = useCallback(async () => {
    try {
      const res = await getLocations(role_key, location_key);
      const locations = res.map((loc) => ({
        value: loc.location_id,
        label: loc.name,
      }));

      setLocationOptions(locations);
    } catch (error) {
      console.log(error);
    }
  }, [role_key, location_key]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations, location_key]);

  const renderContent = useCallback(() => {
    if (!localAdmins) return "Loading...";

    if (localAdmins.length < 1) return "No data found.";

    return localAdmins.map((admin) => (
      <AdminCard key={admin.Username} adminType="local" data={admin} />
    ));
  }, [localAdmins]);

  const renderLocationOptions = useCallback(() => {
    return locationOptions.map((loc) => {
      return (
        <option key={loc.value} value={loc.value}>
          {loc.label}
        </option>
      );
    });
  }, [locationOptions]);

  const renderTitle = () => {
    if (getRoleKey(role_key) !== process.env.REACT_APP_MASTER_ADMIN_KEY)
      return null;

    if (!locationOptions) return "Loading...";

    return (
      <div className="title-container">
        <p className="title">Local Admin</p>
        <select
          className="locations-dropdown"
          value={localAccountsLocationId}
          onChange={(e) => setLocalAccountsLocationId(e.target.value)}
        >
          {renderLocationOptions()}
        </select>
      </div>
    );
  };

  return (
    <Wrapper>
      {renderTitle()}
      <p className="title location-name">
        {
          locationOptions?.find(
            (loc) => loc.value === Number(localAccountsLocationId)
          )?.label
        }
      </p>
      <div className="cards-container">{renderContent()}</div>
    </Wrapper>
  );
}

export default observer(LocalAdmins);
