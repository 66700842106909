function getRoleKey(role) {
  let roleKey;

  switch (role) {
    case "dev":
      roleKey = process.env.REACT_APP_MASTER_ADMIN_KEY;
      break;
    case "master_admin":
      roleKey = process.env.REACT_APP_MASTER_ADMIN_KEY;
      break;
    case "admin":
      roleKey = process.env.REACT_APP_ADMIN_KEY;
      break;
    case "local_master_admin":
      roleKey = process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY;
      break;
    case "local_admin":
      roleKey = process.env.REACT_APP_LOCAL_ADMIN_KEY;
      break;
    default:
      roleKey = process.env.REACT_APP_LOCAL_ADMIN_KEY;
      break;
  }

  return roleKey;
}

export { getRoleKey };
