import React, { useContext, useEffect } from "react";
import { StoreContext } from "stores/store.context";
import AdminCard from "./AdminCard";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";

export const adminAccountsStyle = css`
  width: 100%;

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .cards-container {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .hr-bottom {
    margin-top: 2rem;
  }
`;

const Wrapper = styled.div`
  ${adminAccountsStyle};
`;

function NationalAdmins() {
  const {
    UserStore: { profile },
    UiStore: { nationalAdmins, setNationalAdmins },
  } = useContext(StoreContext);
  const { role_key, location_key } = profile;

  useEffect(() => {
    if (nationalAdmins == null) {
      setNationalAdmins(role_key, location_key);
    }
  }, [role_key, location_key, nationalAdmins, setNationalAdmins]);

  const renderContent = () => {
    if (!nationalAdmins) return "Loading...";

    if (nationalAdmins.length < 1) return "No data found.";

    return nationalAdmins.map((admin) => (
      <AdminCard key={admin.Username} adminType="national" data={admin} />
    ));
  };

  return (
    <Wrapper>
      <div className="title-container">
        <p className="title">National Admin</p>
      </div>
      <div className="cards-container">{renderContent()}</div>
      <hr className="hr-bottom" />
    </Wrapper>
  );
}

export default observer(NationalAdmins);
