import React, { useState, useContext } from "react";
import { Wrapper } from "./Topbar.styled";
import { observer } from "mobx-react-lite";
import CreateAdButton from "./CreateAdButton";
import TopbarDropdownMenu from "./TopbarDropdownMenu";
import PlaceholderPhoto from "assets/topbarphoto_placeholder.svg";
import { StoreContext } from "stores/store.context";
import { getRoleKey } from "helpers/Role.helper";
import syncLogo from "assets/sync_icon_dark.svg";
import { Link } from "react-router-dom";

function Topbar({ showMenu, title }) {
  const { UserStore, UiStore } = useContext(StoreContext);
  const { profile } = UserStore;
  const { setModalContent, setIsGlobalModalOpen, unsyncedDates, syncErrors } =
    UiStore;

  const [expandMenu, setExpandMenu] = useState(false);

  const roleKey = getRoleKey(profile.role_key);

  const renderCreateAdButton = () => {
    if (
      roleKey !== process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY
    )
      return null;

    return <CreateAdButton />;
  };

  const openSyncModal = () => {
    setModalContent("syncNotif");
    setIsGlobalModalOpen(true);
  };

  const renderViewSyncInfo = () => {
    if (
      roleKey !== process.env.REACT_APP_LOCAL_MASTER_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_LOCAL_ADMIN_KEY
    )
      return null;

    return (
      <>
        <div className="pipe" />
        <div>
          <img
            className="view-sync"
            src={syncLogo}
            alt=""
            onClick={openSyncModal}
          />
          {unsyncedDates && syncErrors && (
            <div
              className={
                unsyncedDates > 0 || syncErrors.length > 0
                  ? "sync-late"
                  : "sync-on-time"
              }
            />
          )}
        </div>
      </>
    );
  };

  const userDetails = () => {
    return (
      <div className="user-details">
        <Link to="/account">
          <img src={profile.profile_picture || PlaceholderPhoto} alt="" />
        </Link>
        <p>{profile.name ? "Hi, " + profile.name : ""}</p>
        {renderViewSyncInfo()}
      </div>
    );
  };

  if (showMenu) {
    return (
      <Wrapper>
        <h1 className="title">{title}</h1>
        <div className="menu">
          <div className={expandMenu ? "" : "hidden"}>
            <TopbarDropdownMenu />
          </div>
          <p
            className="show-search-toggle"
            onClick={() => setExpandMenu(!expandMenu)}
          >
            {expandMenu ? "Hide" : "Show"} Search
          </p>
          {renderCreateAdButton()}
        </div>
        {userDetails()}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 className="title">{title}</h1>
      {userDetails()}
    </Wrapper>
  );
}

export default observer(Topbar);
