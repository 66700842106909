import styled from 'styled-components';

/* Assets */
import background from "assets/login_background.png";
import desktopBackground from "assets/login_background_desktop.png";

const Wrapper = styled.div`
  background: #ed7c52 url(${background}) no-repeat;
  background-position: bottom -205px left 0;
  background-size: contain;
  min-height: 100vh;
  padding: 3.375rem;

  @media (min-height: 640px) and (orientation: portrait) {
    background-position: bottom -150px left 0;
  }

  @media (min-height: 740px) and (orientation: portrait) {
    background-position: bottom -75px left 0;
  }

  @media (min-height: 840px) and (orientation: portrait) {
    background-position: bottom 0 left 0;
  }

  @media (min-width: 768px) {
    background: #ed7c52 url(${desktopBackground}) no-repeat;
    background-position: bottom 0 center;
    background-size: 150%;
    padding-top: 10rem;
  }

  @media (min-width: 1024px) {
    background-size: 100%;
    background-position: bottom 0px center;
    padding-top: 5rem;
  }

  @media (min-width: 1280px) {
    background-position: bottom -150px center;
  }

  @media (min-width: 1440px) {
    background-position: bottom -175px center;
    padding-top: 7.5rem;
  }

  @media (min-width: 1536px) {
    background-position: bottom -200px center;
  }

  @media (min-width: 1920px) {
    background-position: bottom -200px center;
  }
`;

export {
    Wrapper,
}