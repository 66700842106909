import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { StoreContext } from "stores/store.context";
import { observer } from "mobx-react-lite";

/* Components */
import Topbar from "./Topbar";
import MobileNavbar from "./MobileNavbar";
import Navbar from "./Navbar";
import Modal from "components/modal";
import { getRoleKey } from "helpers/Role.helper";
import { getLocations, getOfficers } from "services/AdsApi";
import moment from "moment";

const Wrapper = styled.div`
  .main {
    @media (min-width: 1024px) {
      margin-left: 14.65rem;
    }
  }

  .main-collapsed-navbar {
    @media (min-width: 1024px) {
      margin-left: 5rem;
    }
  }

  .visible-modal {
    display: initial;
  }

  .hidden-modal {
    display: none;
  }
`;

function AppLayout({ children, page, showSearch }) {
  const { UiStore, UserStore } = useContext(StoreContext);
  const { role_key, location_key } = UserStore?.profile;
  const {
    isNavbarExpanded,
    setIsNavbarExpanded,
    isGlobalModalOpen,
    setIsGlobalModalOpen,
    setModalContent,
    unsyncedDates,
    setUnsyncedDates,
    syncErrors,
    setSyncErrors,
    setOfficers,
  } = UiStore;

  const [locations, setLocations] = useState(null);

  const showSyncNotif = localStorage.getItem("showSyncNotif");

  const roleKey = getRoleKey(role_key);

  useEffect(() => {
    if (isGlobalModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isGlobalModalOpen]);

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await getLocations(role_key, location_key);
      const locData = data[0];

      if (locData.folder_id == null) {
        setSyncErrors(["No drive folder"]);
        setLocations(data);
        return;
      }

      if (locData.last_synced == null) {
        setSyncErrors(["No sync data"]);
        setLocations(data);
        return;
      }

      const lastSynced = moment(locData.last_synced)?.format("YYYY-MM-DD");

      const currentDate = moment().startOf("day");
      const currentTime = moment().format("HH:mm");

      const daysDiff = moment.duration(currentDate.diff(lastSynced)).asDays();
      let absDaysDiff =
        Math.abs(daysDiff) - locData?.skipped_synced_dates?.length;

      if (currentTime <= "02:00") absDaysDiff -= 1;

      setSyncErrors([]);
      setUnsyncedDates(absDaysDiff);
      setLocations(data);
    };

    fetchLocations();
  }, [location_key, role_key, setSyncErrors, setUnsyncedDates]);

  useEffect(() => {
    if (
      (unsyncedDates > 0 || syncErrors?.length > 0) &&
      roleKey !== process.env.REACT_APP_ADMIN_KEY &&
      roleKey !== process.env.REACT_APP_MASTER_ADMIN_KEY &&
      JSON.parse(showSyncNotif)
    ) {
      setModalContent("syncNotif");
      setIsGlobalModalOpen(true);
    }
  }, [
    unsyncedDates,
    locations,
    roleKey,
    setIsGlobalModalOpen,
    setModalContent,
    showSyncNotif,
    syncErrors?.length,
  ]);

  useEffect(() => {
    const fetchOfficers = async () => {
      const data = await getOfficers(role_key, location_key);
      setOfficers(data);
    };

    fetchOfficers();
  }, [setOfficers, role_key, location_key]);

  return (
    <Wrapper>
      <MobileNavbar title={page} />
      <Navbar
        isNavbarExpanded={isNavbarExpanded}
        setIsNavbarExpanded={setIsNavbarExpanded}
      />
      <div className={isNavbarExpanded ? "main" : "main-collapsed-navbar"}>
        <Topbar title={page} showMenu={showSearch} />
        {children}
      </div>
      <div className={isGlobalModalOpen ? "visible-modal" : "hidden-modal"}>
        <Modal />
      </div>
    </Wrapper>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
};

export default observer(AppLayout);
