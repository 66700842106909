import React, { useEffect, useContext, useState, useCallback } from "react";
import Table from "components/common/table";
import { getLocations } from "services/AdsApi";
import { Wrapper } from "./StationsDatatable.styled";
import syncLogo from "assets/sync_icon.svg";
import syncLogoDark from "assets/sync_icon_dark.svg";
import { StoreContext } from "stores/store.context";
import { observer } from "mobx-react-lite";
import Spinner from "components/common/spinner";
import moment from "moment";
import { deleteStation, syncLogs } from "services/StationApi";

import { Link } from "react-router-dom";
import { MdOutlineDeleteOutline, MdOutlineEdit } from "react-icons/md";
import Swal from "sweetalert2";

function StationsDatatable() {
  const { UserStore, UiStore } = useContext(StoreContext);
  const { role_key, location_key } = UserStore?.profile;
  const { setIsGlobalModalOpen, setModalContent, setSyncErrors } = UiStore;

  const [dataPagination, setDataPagination] = useState(1);
  const [locations, setLocations] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);

  const fetchLocations = useCallback(async () => {
    const data = await getLocations(role_key, location_key);
    setLocations(data);
  }, [location_key, role_key]);

  useEffect(() => {
    if (role_key) {
      fetchLocations();
    }
  }, [page, role_key, location_key, fetchLocations]);

  const handleSync = useCallback(
    async (role_key, locationId) => {
      setLocations(null);
      setSyncErrors([]);
      const syncErrors = [];
      const newLocations = locations;

      try {
        const data = await syncLogs(role_key, locationId);

        if (data.error) {
          syncErrors.push("An error occured while syncing logs.");
        } else {
          const syncedLocations = data?.results;

          syncedLocations?.forEach((loc) => {
            const locIndex = newLocations.findIndex(
              (l) => l.location_id === loc.location_id
            );

            newLocations[locIndex] = {
              activation_date: loc.activation_date,
              folder_id: loc.folder_id,
              last_sequential_synced: loc.last_sequential_synced,
              last_synced: loc.last_synced,
              unsynced_dates: loc.unsynced_dates,
              location_id: loc.location_id,
              name: loc.name,
              short_name: loc.short_name,
              skipped_synced_dates: loc.skipped_synced_dates,
            };

            if (loc.error) {
              syncErrors.push(`${loc.name}: ${loc.error.replaceAll("-", " ")}`);
            }

            if (loc.unsynced_dates) {
              loc.unsynced_dates.forEach((date) => {
                const unsyncedDate = moment(date).format("MMM DD, YYYY");
                syncErrors.push(
                  `${loc.name}: ${unsyncedDate} - missing log file`
                );
              });
            }
          });
        }
      } catch (error) {
        console.log(error);
        /* do nothing */
      }
      setLocations(newLocations);
      setSyncErrors(syncErrors);
      setIsGlobalModalOpen(true);
      setModalContent("syncComplete");
    },
    [locations, setIsGlobalModalOpen, setModalContent, setSyncErrors]
  );

  const handleDeleteStation = useCallback(
    (location_id) => {
      Swal.fire({
        title: "Are you sure you want to delete this station?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        confirmButtonColor: "#ED7C52",
        cancelButtonColor: "#1D5C87",
        reverseButtons: true,
      }).then((res) => {
        if (res.isConfirmed) {
          deleteStation(role_key, location_id).then((r) => {
            if (r.error) {
              Swal.fire("Error!", "Something went wrong", "error");
              return;
            }
            Swal.fire(
              "Station deleted!",
              "Station is successfully deleted",
              "success"
            );
            fetchLocations();
          });
        }
        return;
      });
    },
    [fetchLocations, role_key]
  );

  const renderLastSynced = (tableInfo) => {
    const lastSyncedTemp = tableInfo.data[tableInfo.row.index].last_synced;
    const lastSynced = moment(lastSyncedTemp)?.format("MMM DD, YYYY");

    return (
      <div>
        <p>{lastSyncedTemp ? lastSynced : "No Data"}</p>
      </div>
    );
  };

  const renderStatus = (tableInfo) => {
    if (tableInfo.data[tableInfo.row.index].folder_id == null) {
      return (
        <div>
          <p>No drive folder</p>
        </div>
      );
    }

    const skippedSyncDates =
      tableInfo.data[tableInfo.row.index].skipped_sync_dates;

    const lastSyncedTemp =
      tableInfo.data[tableInfo.row.index].last_synced ||
      tableInfo.data[tableInfo.row.index].activation_date;
    const lastSynced = moment(lastSyncedTemp)?.format("YYYY-MM-DD");

    const currentDate = moment().startOf("day");
    const currentTime = moment().format("HH:mm");

    const daysDiff = moment.duration(currentDate.diff(lastSynced)).asDays();
    let absDaysDiff = Math.abs(daysDiff) - skippedSyncDates?.length;

    if (currentTime <= "02:00") absDaysDiff -= 1;

    let status;

    if (absDaysDiff === 1) {
      status = <p className="late">{absDaysDiff} unsynced date</p>;
    } else if (absDaysDiff > 0) {
      status = <p className="late">{absDaysDiff} unsynced dates</p>;
    } else {
      status = <p className="updated">Updated</p>;
    }

    return <div>{lastSyncedTemp ? status : <p>No sync data</p>}</div>;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Location",
        accessor: "name",
      },
      {
        Header: "Activation Date",
        accessor: "activation_date",
      },
      {
        Header: "Last Update",
        accessor: "last_synced",
        Cell: (tableInfo) => renderLastSynced(tableInfo),
      },
      {
        Header: "Status",
        accessor: "sync_status",
        Cell: (tableInfo) => renderStatus(tableInfo),
      },
      {
        Header: () => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.25rem",
              alignItems: "center",
            }}
          >
            <div>Sync All</div>
            <div style={{ cursor: "pointer" }}>
              <img
                onClick={() => handleSync(role_key)}
                src={syncLogo}
                style={{ height: "18px", marginTop: "5px" }}
                alt="export"
              />
            </div>
          </div>
        ),
        Cell: (tableInfo) => {
          const locationId = tableInfo.data[tableInfo.row.index].id;

          return (
            <div
              style={{ cursor: "pointer", display: "flex", gap: "0.125rem" }}
            >
              <img
                onClick={() => handleSync(role_key, locationId)}
                src={syncLogoDark}
                style={{ height: "18px" }}
                alt="export"
              />
              <Link
                className="action-button"
                to={`/edit-station/${tableInfo.data[tableInfo.row.index].id}`}
              >
                <MdOutlineEdit color="black" size={18} />
              </Link>
              <MdOutlineDeleteOutline
                className="action-button"
                onClick={() =>
                  handleDeleteStation(tableInfo.data[tableInfo.row.index].id)
                }
                size={18}
              />
            </div>
          );
        },
        accessor: "actions",
        disableSortBy: true,
      },
    ],
    [handleDeleteStation, handleSync, role_key]
  );

  const data = locations?.map((loc) => {
    return {
      id: loc.location_id,
      name: loc.name,
      activation_date: moment(loc.activation_date).format("MMM DD, YYYY"),
      folder_id: loc.folder_id,
      last_synced: loc.last_synced,
      skipped_sync_dates: loc.skipped_synced_dates,
    };
  });

  if (!locations) return <Spinner />;

  const dataDisplayed = data.slice(
    (dataPagination - 1) * pageSize,
    dataPagination * pageSize
  );

  return (
    <Wrapper>
      <div className="btn-container">
        <Link to="/create-station" className="create-station-btn">
          Create New Station
        </Link>
      </div>
      <Table
        columns={columns}
        data={dataDisplayed}
        dataPagination={dataPagination}
        hasPrevData={dataPagination > 1 ? true : false}
        hasMoreData={data.length > dataPagination * pageSize ? true : false}
        manualPageIndex={page}
        manualSetPageIndex={setPage}
        manualSortBy={false}
        pageSize={pageSize}
        setDataPagination={setDataPagination}
        setPageSize={setPageSize}
        showTableButtons={false}
      />
    </Wrapper>
  );
}

export default observer(StationsDatatable);
