import React from "react";
import { usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import {
  Wrapper,
  TableWrapper,
  TableHeader,
  TableBody,
  TableButtons,
  TableRow,
  HeaderCell,
  DataCell,
  Pagination,
} from "./Table.styled";
import sortAscIcon from "assets/sort_asc.svg";
import sortDefaultIcon from "assets/default_sort.svg";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
} from "react-icons/ai";

function Table({
  columns,
  data,
  dataPagination,
  hasMoreData,
  hasPrevData,
  manualPageCount,
  manualPageIndex,
  manualPagination = true,
  manualSetPageIndex,
  manualSortBy = true,
  onHeaderClick,
  setDataPagination,
  showPage = true,
  showTableButtons = true,
  pageSize,
  setPageSize,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      manualPagination,
      manualSortBy,
      pageCount: manualPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const renderTableButtons = () => {
    if (!showTableButtons) return null;

    return (
      <TableButtons>
        <Pagination>
          <button
            onClick={() =>
              manualPagination ? manualSetPageIndex(0) : gotoPage(0)
            }
            disabled={manualPagination ? !manualPageIndex : !canPreviousPage}
          >
            <AiOutlineDoubleLeft color="white" size="12px" />
          </button>
          <button
            onClick={() =>
              manualPagination
                ? manualSetPageIndex(manualPageIndex - 1)
                : previousPage()
            }
            disabled={manualPagination ? !manualPageIndex : !canPreviousPage}
          >
            <AiOutlineLeft color="white" size="12px" />
          </button>
          <div className="go-to-container">
            <span>Go to page:</span>
            <select
              className="go-to-dropdown dropdown"
              value={manualPageIndex + 1}
              onChange={(e) => {
                manualSetPageIndex(e.target.value - 1);
              }}
            >
              {[...Array(manualPageCount).keys()].map((page) => (
                <option key={page} value={page + 1}>
                  {page + 1}
                </option>
              ))}
            </select>
          </div>
          <button
            onClick={() =>
              manualPagination
                ? manualSetPageIndex(manualPageIndex + 1)
                : nextPage()
            }
            disabled={
              manualPagination
                ? manualPageIndex === pageCount - 1
                : !canNextPage
            }
          >
            <AiOutlineRight color="white" size="12px" />
          </button>
          <button
            onClick={() =>
              manualPagination
                ? manualSetPageIndex(manualPageCount - 1)
                : gotoPage(pageCount - 1)
            }
            disabled={
              manualPagination
                ? manualPageIndex === pageCount - 1
                : !canNextPage
            }
          >
            <AiOutlineDoubleRight color="white" size="12px" />
          </button>
          {showPage && (
            <span className="page-number-container">
              Page{" "}
              <strong className="page-number">
                {manualPageIndex + 1} of{" "}
                {manualPagination ? manualPageCount : pageOptions.length}
              </strong>
            </span>
          )}
        </Pagination>
        <div className="page-size">
          <span className="show">Show</span>
          <select
            className="dropdown"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </TableButtons>
    );
  };

  /* headers without sort buttons */
  const noSortHeaders = [
    "checkbox",
    "actions",
    "sync_status",
    "date",
    "sponsorship",
    "announcers",
    "block_time",
    "time_check",
    "commercials",
    "edit",
  ];

  return (
    <Wrapper>
      <TableWrapper {...getTableProps()} cellSpacing={0}>
        <TableHeader>
          {headerGroups?.map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              {...headerGroup?.getHeaderGroupProps()}
            >
              {headerGroup?.headers?.map((column) => {
                return manualSortBy ? (
                  <HeaderCell
                    key={column.id}
                    {...column?.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => onHeaderClick && onHeaderClick(column)}
                  >
                    <div className="header-cell-content">
                      {column?.render("Header")}
                      {!noSortHeaders.includes(column.id) && (
                        <span>
                          {column.sortDirection === "ASC" ? (
                            <img src={sortAscIcon} alt="sort-asc" />
                          ) : column.sortDirection === "DESC" ? (
                            <img
                              src={sortAscIcon}
                              style={{
                                transform: "rotate(180deg)",
                              }}
                              alt="sort-desc"
                            />
                          ) : (
                            <img src={sortDefaultIcon} alt="sort-default" />
                          )}
                        </span>
                      )}
                    </div>
                  </HeaderCell>
                ) : (
                  <HeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="header-cell-content">
                      {column.render("Header")}
                      {!noSortHeaders.includes(column.id) && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <img
                                src={sortAscIcon}
                                style={{
                                  transform: "rotate(180deg)",
                                }}
                                alt="sort-desc"
                              />
                            ) : (
                              <img src={sortAscIcon} alt="sort-asc" />
                            )
                          ) : (
                            <img src={sortDefaultIcon} alt="sort-default" />
                          )}
                        </span>
                      )}
                    </div>
                  </HeaderCell>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {page?.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow key={row.id} {...row?.getRowProps()}>
                {row?.cells.map((cell) => {
                  return (
                    <DataCell key={cell.column.id} {...cell?.getCellProps()}>
                      {cell?.render("Cell")}
                    </DataCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </TableWrapper>
      <div className="sub-pagination-container">
        {hasPrevData && (
          <button
            className="load-prev-btn"
            onClick={() => setDataPagination(dataPagination - 1)}
            type="button"
          >
            Load Previous
          </button>
        )}
        {hasMoreData && (
          <button
            className="load-more-btn"
            onClick={() => setDataPagination(dataPagination + 1)}
            type="button"
          >
            Load Next
          </button>
        )}
      </div>
      {renderTableButtons()}
    </Wrapper>
  );
}

export default React.memo(Table);
