import React from 'react';

function Schedule({startDate, endDate, handleStartDateChange, handleEndDateChange}) {
    
    return (
        <>
            <input
                className='search-field date-field start-date'
                placeholder='Start Date'
                type='date'
                name='startDate'
                onChange={handleStartDateChange}
                value={startDate}
            />
            <input
                className='search-field date-field'
                placeholder='End Date'
                type='date'
                name='endDate'
                min={startDate}
                onChange={handleEndDateChange}
                value={endDate}
            />
        </>
    )
}

export default Schedule;
