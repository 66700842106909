import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.button`
  background: transparent;
  border: solid #FFF 3px;
  border-radius: 1rem;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  height: 3.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &:active {
    background-color: ${(props) => props.theme.colors.secondaryActive};
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.disabled};
    border: none;
  }
`;

function GhostButton({ title, onClick, type, isDisabled }) {
  return (
    <Wrapper onClick={onClick} type={type} disabled={isDisabled}>
      {title}
    </Wrapper>
  );
}

GhostButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default GhostButton;
