import React, { useEffect, useContext, useState } from "react";
import Table from "components/common/table";
import { TimesPlayedWrapper } from "./AdLogsDatatable.styled";
import { StoreContext } from "stores/store.context";
import { observer } from "mobx-react-lite";
import Spinner from "components/common/spinner";
import { AdStatusWrapper } from "../AdViewDatatable/AdViewDatatable.styled";

function AdLogsDatatable() {
  const { AdsStore, UserStore } = useContext(StoreContext);
  const { role_key, location_key } = UserStore?.profile;
  const {
    loading,
    adCount,
    adLogs,
    adQueryParams,
    storeGetAds,
    storeGetAdLogs,
  } = AdsStore;

  const [dataPagination, setDataPagination] = useState(1);
  const [page, setPage] = useState(0);
  const [limit] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const fetchAdLogs = async () => {
      await storeGetAdLogs(
        {
          ...adQueryParams,
          offset: page * limit,
        },
        role_key,
        location_key
      );
    };

    if (role_key) {
      fetchAdLogs();
    }

    if (!adCount) storeGetAds({}, role_key, location_key);
    setDataPagination(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, role_key, location_key]);

  const timesPlayed = (timesPlayed) => {
    if (timesPlayed.length < 1) {
      return <p>Not Played</p>;
    }

    return (
      <p className="times-played">
        {timesPlayed.map((tp, index) => {
          return (
            <>
              <span className={tp.status ? "on-time" : "not-on-time"} />
              {index === timesPlayed.length - 1 ? `${tp.time}` : `${tp.time}, `}
            </>
          );
        })}
      </p>
    );
  };

  const status = (status) => {
    const className = status?.toLowerCase().split(" ").join("-");

    return <div className={className}>{status}</div>;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Client",
        accessor: "client",
      },
      {
        Header: "Product Name",
        accessor: "product_name",
      },
      {
        Header: "Ad Type",
        accessor: "type",
      },
      {
        Header: "Media Value",
        accessor: "media_value",
      },
      {
        Header: "Date Played",
        accessor: "date_played",
      },
      {
        Header: "Ad Schedule",
        accessor: "ad_schedule",
      },
      {
        Header: "Times Played",
        accessor: "times_played",
        Cell: (tableInfo) => (
          <TimesPlayedWrapper>
            {timesPlayed(tableInfo.data[tableInfo.row.index].times_played)}
          </TimesPlayedWrapper>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (tableInfo) => (
          <AdStatusWrapper>
            {status(tableInfo.data[tableInfo.row.index].status)}
          </AdStatusWrapper>
        ),
      },
      {
        Header: "Location",
        accessor: "location_name",
      },
    ],
    []
  );

  const data = adLogs?.map((ad) => {
    return {
      ad_id: ad?.ad_id,
      client: ad?.client,
      product_name: ad?.product_name,
      type: ad?.type,
      media_value: ad?.media_value,
      date_played: ad?.date_played,
      ad_schedule: ad?.ad_schedule,
      times_played: ad?.times_played,
      status: ad?.media_value_status,
      location_name: ad?.location.name,
    };
  });

  if (loading) return <Spinner />;

  const dataDisplayed = data.slice(
    (dataPagination - 1) * pageSize,
    dataPagination * pageSize
  );

  return (
    <Table
      columns={columns}
      data={dataDisplayed}
      dataCount={adCount}
      dataPagination={dataPagination}
      hasPrevData={dataPagination > 1 ? true : false}
      hasMoreData={data.length > dataPagination * pageSize ? true : false}
      manualPageCount={Math.ceil(adCount / limit)}
      manualPageIndex={page}
      manualSetPageIndex={setPage}
      manualSortBy={false}
      pageSize={pageSize}
      setDataPagination={setDataPagination}
      setPageSize={setPageSize}
    />
  );
}

export default observer(AdLogsDatatable);
